import draggableInit from './draggable';
import { replaceInnerHtmlByEl } from './ce_workers';
import nurtureEntrances from './ce_nurture_entrances';
import ConvertEngineEvents from './ce_events';
import handleWorkflowAction from './ce_workflow_actions';
class ConvertEngine {
    constructor() {
        this.api_key = localStorage.getItem('ce_api_key');

        this.state = {}
        this.loadSessionState();

        this.local_state = {};
        this.loadLocalState();

        if (!this.api_key) this.handleLogout(false)

        this.user = this.getUserInfo();

        this.search_history = this.getSearchHistory();

        this.handleLogin = this.handleLogin.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);

        this.api_url = "https://6rh1hnm5y1.execute-api.ap-southeast-2.amazonaws.com"; // TODO: Add a consistent URL here when we pass an API key
        // this.api_url = "https://api.convertengine.com.au"; 
        this.api_version = "v1";

        this.events = new ConvertEngineEvents();

        return this;
    }
    updateGtagForUser(user) {
        this;
        try {
            window.gtag('config', 'G-E5DPRVK3EG', {
                'user_id': user.id
              });
        } catch(err) {
            console.warn('CE: An error occured assigning Gtag to session:', err.message)
        }
    }
    getUserInfo() {
        const user = localStorage.getItem('ce_user');
        try {
            if (!user?.length) return {}
            const parsedUser = JSON.parse(user || "{}");
            this.updateGtagForUser(user); // link the gtag to the session
            return parsedUser;
        } catch(err) {
            console.error('CE:ERR - An error occured when attempting to get user info:', err.message)
            // TODO: Add a better error handler to show user info
        };
        return null;
    }
    handleLogout(isAuthed) {
        console.info('CE: Logging out..')
        this.setState('isLoggedOut', true);
        if (isAuthed) {
            console.info('CE: logging out')
            // remove local state
            localStorage.removeItem('ce_api_key');
            localStorage.removeItem('ce_user');
            // also clear the session states
            sessionStorage.removeItem('ce_state');
        };
        let onReturnPath = isAuthed ? "" : `?returnPath=${encodeURIComponent(window.location.pathname + window.location.search)}`;

        if (window.location.pathname === "/login.html") return null;

        return window.location.replace('/login.html' + onReturnPath)
    }
    async handleLogin(event) {
        console.info('CE: Logging in...')
        this.setState('isLoggedIn', false);
        event.preventDefault()
        const data = new FormData(event.target);
        const dataObj = Object.fromEntries(data.entries());
        if (!dataObj.api_key) {
            return null; // TODO: handle this better
        };

        const alertNodeEl = document.getElementById('login_alert')

        if (alertNodeEl) {
            alertNodeEl.parentNode.removeChild(alertNodeEl);
        }

        this.handleActionButtonState(event.target, 'login', 'start');

        const userFromKey = await this.retrieveUserByKey(dataObj.api_key)

        // if err, attempt to append the error container
        if (userFromKey.isErr || !userFromKey.val?.id) {
            const errorVal = userFromKey.val || "An error occured, unable to login."
            
            const alertNodeHtml = `
            <div class="alert alert-danger border-2 d-flex align-items-center" role="alert" id="login_alert">
                <div class="bg-danger me-2 icon-item">
                    <span class="fas fa-times-circle text-white fs-3"></span>
            </div>
            <p class="mb-0 flex-1">${errorVal}</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            `
            document.getElementById("form-error-container").innerHTML+= alertNodeHtml;
            console.warn('CE - Unable to login:', errorVal)
            this.handleActionButtonState(event.target, 'login', 'fail');
            return null;
        }
        
        // store the API keys and the user info
        localStorage.setItem('ce_api_key', dataObj.api_key);

        // store a local user object
        const user = userFromKey.val;
        const localUserObj = {
            id: user.id, // users ID
            name: user.user?.name, // user name
            emails: user?.user?.emails, // user emails 
            associations: user.associations // project associations
        };

        this.handleActionButtonState(event.target, 'login', 'success');

        localStorage.setItem('ce_user', JSON.stringify(localUserObj))

        const params = this.getQueryStringParams()

        const returnPath = params.returnPath;

        this.updateGtagForUser(localUserObj);

        return window.location.replace(returnPath?.length ? returnPath : "/index.html")
        // return 'a';
    }
    async handleForgotPassword(event) {
        event.preventDefault()
        const data = new FormData(event.target);
        const dataObj = Object.fromEntries(data.entries());
        console.info(dataObj, 'FORGOT OBJ')
        if (!dataObj.email) {
            return null; // TODO: handle this better
        };

        // return the request
        return this.sendPasswordResetEmail(dataObj.email)
    }
    getQueryStringParams() {
        this;
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
        
          return params;
    }
    loadSessionState() {
        const state = sessionStorage.getItem('ce_state');
        if (!state) return null;
        try {
            this.state = Object.assign({}, this.state, JSON.parse(state))
        } catch(err) {
            console.error('CE:ERR - An error occured when attempting to parse session state:', err.message)
        }
        return null;
    }
    loadLocalState() {
        const state = localStorage.getItem('ce_state');
        if (!state) return null;
        try {
            this.local_state = Object.assign({}, this.local_state, JSON.parse(state))
        } catch(err) {
            console.error('CE:ERR - An error occured when attempting to parse local state:', err.message)
        }
        return null;
    }
    setState(key, value, sessionStore = false) {
        this.state[key] = value;
        const sessionStateObj = Object.assign(
            {},
            this.state,
            { project: null, lead: null }) // clear out the values we don't want to store
        if (sessionStore) sessionStorage.setItem('ce_state', JSON.stringify(sessionStateObj));
        return null;
    }
    setLocalState(key, value, localStore = false) {
        this.local_state[key] = value;
        const localStateObj = Object.assign(
            {},
            this.local_state,
            { project: null, lead: null }) // clear out the values we don't want to store
        if (localStore) localStorage.setItem('ce_state', JSON.stringify(localStateObj));
        return null;
    }
    async handleAndParseResponseObject(res) {
        this;
        const json = await res.json() || {};
        // if ok, return the body
        if (res.ok) return json;
        // otherwise, bubble up the error from the API
        throw new Error(json.message || "An error occured. If this persists, please contact us");
    }
    async apiQuery(url, method, data = null, options = {}) {
        if (!url || !method) return this.returnErrorState('Missing required fields { url, method }');
        try {
            let queryMethod = {
                method,
                headers: this.generateAuthHeaders(),
                ...options
            };
            if (data) queryMethod.body = JSON.stringify(data);
            console.log(queryMethod, "METHOD")
            const queryRes = await fetch(this.generateRawUrl(url), queryMethod)
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned query:', queryRes);
            return this.returnSuccessState(queryRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async apiLongQuery(url, method, data = null, timeout, isRawUrl = false) {
        if (!url || !method) return this.returnErrorState('Missing required fields { url, method }');

        return new Promise((resolve) => {
            try {
                let queryMethod = {
                    method,
                    headers: this.generateAuthHeaders()
                };
                if (data) queryMethod.body = JSON.stringify(data);
                const xmlhttp = new XMLHttpRequest();   // new HttpRequest instance 
                const formattedUrl = isRawUrl ? url : this.generateRawUrl(url);
                xmlhttp.open("POST", formattedUrl, true);
                xmlhttp.setRequestHeader("Content-Type", "application/json");
                xmlhttp.setRequestHeader("Authorization", queryMethod.headers.Authorization);
                if (timeout) xmlhttp.timeout = timeout;
                xmlhttp.send(JSON.stringify(data || {}));
                xmlhttp.onload = () => {
                    let response;
                    try {
                        response = JSON.parse(xmlhttp.response);
                    } catch(err) {
                        console.warn('CE:ERR - Could not parse long query response:', err.message)
                        response = {
                            message: "An error occured"
                        }
                    }

                    if (xmlhttp.status === 200) {
                        resolve(this.returnSuccessState(response));
                    } else {
                        resolve(this.returnErrorState(response.message))
                    }
                    return null;
                };
                xmlhttp.onerror = (event) => {
                    console.warn('CE:ERR - An error occured with Long Query:', event.message);
                    resolve(this.returnErrorState(event.message));
                    return null;
                }
                return null;
            } catch(err) {
                return resolve(this.returnErrorState(err.message))
            }
        })
    }
    async retrieveUserByKey(apiKey) {
        if (!apiKey) return this.returnErrorState('Missing required fields { id, project }');
        try {
            const user = await fetch(this.generateRequestUrl('user', 'retrieve-user-by-key'), {
                method: "GET",
                headers: this.generateAuthHeaders(apiKey)
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned user:', user);
            return this.returnSuccessState(user)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async retrievePublicUserInfoById(id) {
        if (this.state.users?.[id]) {
            return this.returnSuccessState(this.state.users?.[id]);
        }
        if (!id) return this.returnErrorState('Missing required fields: id');
        try {
            const data = { user_id: id }
            const userRes = await fetch(this.generateRequestUrl('user', 'retrieve-public-user-info-by-id'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned user:', userRes.user);
            this.setState('users', Object.assign({}, this.state.users || {}, { [userRes.user.id]: userRes.user }), true);
            return this.returnSuccessState(userRes.user)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async retrieveWorkflowDataForProject(project) {
        if (this.state.workflows?.[project]) {
            return this.returnSuccessState(this.state.workflows?.[project]);
        };
        if (!project) return this.returnErrorState('Missing required fields: { project }');
        try {
            const userRes = await fetch(this.generateRequestUrl(project, 'workflows/get-workflows'), {
                method: "GET",
                // body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned workflow:', userRes.workflow_config);
            this.setState('workflows', Object.assign({}, this.state.workflows || {}, { [project]: userRes.workflow_config }), true);
            return this.returnSuccessState(userRes.workflow_config)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async retrieveUserAssociationsForCompany(company) {
        if (this.state.company_associations?.[company]) {
            return this.returnSuccessState(this.state.company_associations?.[company]);
        }
        if (!company) return this.returnErrorState('Missing required fields: { company }');
        try {
            const assocRes = await fetch(this.generateRequestUrl('company/' + company, 'get-user-associations'), {
                method: "GET",
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned associations:', assocRes.associations);
            this.setState('company_associations', Object.assign({}, this.state.company_associations || {}, { [company]: assocRes.associations }), true);
            return this.returnSuccessState(assocRes.associations)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async sendPasswordResetEmail(email) {
        if (!email) return this.returnErrorState('Missing required fields: email');
        try {
            const data = { email }
            const user = await fetch(this.generateRequestUrl('user', 'reset-password'), {
                method: "POST",
                body: JSON.stringify(data)
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned user:', user);
            return this.returnSuccessState(user)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async getLeadById(id, projectId, condensed = true) {
        if (!id || !projectId) return this.returnErrorState('Missing required fields { id, project }');
        try {
            const data = { id, condensed };
            const lead = await fetch(this.generateRequestUrl(projectId, 'leads/get-lead'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned lead:', lead);
            this.setState('lead', lead.lead)
            return this.returnSuccessState(lead.lead)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    setPaginationKeys(keys, keystore) {
        console.info('CE: Pagination keys here, storing');
        this.state[keystore] = Object.fromEntries(
            Object.keys(keys).map(keyName => [keyName, keys[keyName] || "QUERY_END" ])
        );
        return null;
    }
    async retrieveLeads(query, keystore) {
        if (!query) return this.returnErrorState('Missing required fields { query }');
        try { 
            console.info('CE: Retrieving leads for user')
            // TODO: Add in ability to flag condensed/uncondensed
            
            let leadPageQuery = query;
            // if paginated state, retrieve
            const paginationKey = `pagination_${keystore || 'all'}`;
            if (this.state[paginationKey]) {
                leadPageQuery.keys = this.state[paginationKey]
            }

            if (this.local_state.project_id) {
                leadPageQuery.project_id = this.local_state.project_id;
            };

            const leads = await fetch(this.generateRequestUrl('user', 'leads/retrieve-leads'), {
                method: "POST",
                body: JSON.stringify(leadPageQuery),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned leads:', leads);
            if (leads.keys) this.setPaginationKeys(leads.keys, paginationKey);
            // this.setState('lead', lead.lead)
            return this.returnSuccessState(leads.leads)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async createNewLead(projectId, data) {
        const { name, email, phone, value } = data;
        if (!name || !email || !phone || !value || !projectId) return this.returnErrorState('Missing required fields { name, email, phone, project, value }');
        try {
            const lead = await fetch(this.generateRequestUrl(projectId, 'leads/create-new-lead'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Created lead:', lead);
            return this.returnSuccessState(lead.lead)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async getInteractionsByLeadId(id, leadId, projectId) {
        if (!id || !leadId) return this.returnErrorState('Missing required fields { id, lead_id }');
        try {
            const data = { id, lead_id: leadId };
            const instance = await fetch(this.generateRequestUrl(projectId, 'engine/get-instance'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Returned lead:', instance);
            this.setState('instance', instance.instance)
            return this.returnSuccessState(instance.instance)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async updateStatusForLead(reason, note, image, invalid, notify) {

        if (!reason || !note) return this.returnErrorState('Missing required fields { reason, note }');
        try {
            const data = {
                id: this.state.lead.id,
                project_id: this.state.lead.project_id,
                is_invalid: invalid,
                invalid: {
                    reason,
                    note,
                    image: image || null
                },
                user: this.user,
                notify_emails: notify // used to notify a set of emails that a request has been made
            };
            const statusRes = await fetch(this.generateRequestUrl(this.state.lead.project_id, 'leads/update-status'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async updateWorkflowStageForLead(leadId, workflowStageId, projectId) {

        if (!leadId || !workflowStageId || !projectId) return this.returnErrorState('Missing required fields { leadId, workflowStageId }');
        try {
            const data = {
                lead_id: leadId,
                workflow_stage_id: workflowStageId
            };
            const statusRes = await fetch(this.generateRequestUrl(projectId, 'leads/update-workflow-stage'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async addInteractionActivityTolead(leadId, projectId, activity) {

        if (!leadId || !projectId || !activity) return this.returnErrorState('Missing required fields { leadId, projectId, activity }');
        try {
            let data = {
                lead_id: leadId,
                interaction: {
                    user: {
                        id: this.user.id,
                        name: this.user.name
                    },
                    type: activity.type,
                    note: activity.note.length ? activity.note : null
                }
            };
            const statusRes = await fetch(this.generateRequestUrl(this.state.lead.project_id, 'leads/add-activity'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async assignLeadToUserById (project, lead, user) {
        
        if (!lead || !project || !user) return this.returnErrorState('Missing required fields { lead, project, user }');
        
        try {
            const data = {
                lead_id: lead,
                lead_owner_id: user
            };
            const statusRes = await fetch(this.generateRequestUrl(project, 'leads/update-owner'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async startSequenceForInstance(data) {

        if (!data) return this.returnErrorState('Missing required fields { ..data }');
        try {
            const statusRes = await fetch(this.generateRequestUrl(this.state.lead.project_id, 'engine/update-sequence'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async cancelSequenceForInstance(id, projectId) {

        if (!id || !projectId) return this.returnErrorState('Missing required fields { id, project_id }');
        try {
            const data = { id }
            const statusRes = await fetch(this.generateRequestUrl(projectId, 'engine/cancel-sequence'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async createEngineInstance(id, projectId) {

        if (!id || !projectId) return this.returnErrorState('Missing required fields { id, project_id }');
        try {
            const data = { id }
            const statusRes = await fetch(this.generateRequestUrl(projectId, 'engine/create-instance'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async searchQueryForProject(project, value) {
        if (!value || !project) return this.returnErrorState('Missing required fields { value, project_id }'); 
        try {
            const data = { value }
            const statusRes = await fetch(this.generateRequestUrl(project, 'search/query'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async searchForLeadLink(company, value) {
        if (!value || !company) return this.returnErrorState('Missing required fields { value, company_id }'); 
        try {
            const data = { value }
            const statusRes = await fetch(this.generateRequestUrl('company/' + company, 'leads/retrieve-lead-by-record-link'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    async createLeadCrmRecordLink(projectId, request) {
        if (!request || !projectId) return this.returnErrorState('Missing required fields { request, project_id }'); 
        try {
            const data = request;
            const statusRes = await fetch(this.generateRequestUrl(projectId, 'leads/update-lead-record-link'), {
                method: "POST",
                body: JSON.stringify(data),
                headers: this.generateAuthHeaders()
            })
            .then(res => this.handleAndParseResponseObject(res));
            console.info('CE: Request completed:', statusRes);
            return this.returnSuccessState(statusRes)
        } catch(err) {
            return this.returnErrorState(err.message)
        }
    }
    getSearchHistory() {
        this;
        const search = localStorage.getItem('ce_search_history');
        const defaultSearchHistory = {
            recent_searches: [],
            recent_viewed: []
        }; // instantiate a new search history object
        try {
            if (!search) return defaultSearchHistory;
            return JSON.parse(search)
        } catch(err) {
            return defaultSearchHistory;
        }
    }
    setSearchHistory() {
        return localStorage.setItem('ce_search_history', JSON.stringify(this.search_history || {}))
    }
    retrieveProjectsFromAssociations() {
        const associations = this.user?.associations;
        if (!associations) return [];

        const validProjects = associations.filter(
            assoc => assoc.id !== "*"
        ).map(
            assoc => assoc.projects?.filter(
                proj => proj.id !== "*"
            ).map(
                proj => proj.id
            )
        ).flat()
        return validProjects;
    }
    generateRequestUrl(
        root,
        path
    ) {
        return this.api_url + "/" + this.api_version + "/" + root +  "/" + path;
    }
    generateRawUrl(
        path
    ) {
        return this.api_url + "/" + this.api_version + "/" + path;
    }
    generateAuthHeaders(apiKey) {
        return {
            'Authorization': 'Bearer ' + (apiKey || this.api_key)
        }
    }
    returnSuccessState(any) {
        this;
        return {
            isOk: true,
            val: any
        }
    }
    returnErrorState(any) {this;
        return {
            isErr: true,
            val: any
        }
    }
    createToastAlert(title, body, state) {
        // TODO:  Improve this with bootstrap utils
        this;
        const toastBody = body ?
        `<div class="toast-body">
            ${body}
        </div>` : '';
        const toast = `
        <div class="toast position-fixed bottom-0 end-0 mb-2 mx-2 always-on-top" role="alert"
            aria-live="assertive" aria-atomic="true">
            <div class="toast-header bg-${state || "primary"} text-white">
                <strong class="me-auto">${title}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast"
                    aria-label="Close">
                </button>
                </div>
            ${toastBody}
        </div>`;
        const toastDiv = document.createElement('div');
        toastDiv.innerHTML = toast;
        const toastEl = document.body.appendChild(toastDiv.children[0]);
        const toaster = new window.bootstrap.Toast(toastEl)
        toaster.show()
        // const 
        return null;
    }
    handleActionButtonState(el, action, type) {
        this;
        if (!el) return null;

        try {
            const targetEl = el.hasAttribute('data-ce_action_btn') ? 
                el :
                (el.querySelector(`[data-ce_action_btn="${action}"]`))
    
            if (!targetEl) return null;
    
            const resetButton = () => {
                const text = targetEl.getAttribute('data-ce_action_text');
                const className = targetEl.getAttribute('data-ce_action_class');
                targetEl.className = className;
                targetEl.innerText = text;
                targetEl.disabled = false;
            }
    
            if (type === "reset") return resetButton();
    
            const message = {
                start: {
                    text: 'Loading...'
                },
                success: {
                    text: "Success!",
                    icon: "fas fa-check",
                    style: "success"
                },
                fail: {
                    text: "Action failed",
                    icon: "fa-regular fa-xmark",
                    style: "danger"
                }
            }
    
            if (type === "start") {
                targetEl.setAttribute('data-ce_action_text', targetEl.innerText);
                targetEl.setAttribute('data-ce_action_class', targetEl.className);
                targetEl.disabled = true;
            }
            targetEl.innerText = message[type]?.text
    
            if (message[type]?.style) {
                targetEl.classList.add('btn-' + message[type]?.style)
            }
    
            if (["fail", "success"].includes(type)) {
                setTimeout(() => resetButton(), 1000)
            }
        } catch(err) {
            console.info('CE:WARN - An error occured with action button state:', err.message, { el, action, type})
        }

        return null;
    }
};window.convertEngine = new ConvertEngine();

// -------------- STRING DECLARATIONS --------------
const callConnectStrings = {
    status: {
        initialized: "🤖 Active",
        connected: "📲 Made Contact",
        connect_attempt: "📞 Attempting Call Connect",
        connect_success: "📞 Call Connected 🏆",
        connect_failed: '❌ Call Connect Failed',
        booking_attempt: "📅 Attempting booking",
        booking_attempt_2: "📅 Attempting booking",
        booking_success: "📅 Booked Callback 🏆",
        booking_complete: "📅 Booking Complete 🏆",
        booking_failed: '❌ Booking Failed',
        booking_retarget: '🎯 Nurturing',
        automation_complete: '✅ Automation Complete',
        retargeting: '🎯 Retargeting'
    },
    status_description: {
        call_initiated: 'ConvertGPT initated a call to the Lead',
        sms_initiated: 'ConvertGPT initiated an SMS conversation with the Lead',
        prompt_call_connect: 'Lead requested to speak to an agent right now',
        prompt_call_callback: 'Lead requested to choose a time for a callback',
        booking_attempt_call: 'ConvertGPT attempted to collect a callback time from the Lead over the phone',
        booking_attempt_2_call: 'ConvertGPT tried a second time to collect a callback time from the Lead over the phone',
        booking_attempt_sms: 'ConvertGPT attempted to collect a callback time from the Lead via SMS',
        booking_timeofday: 'ConvertGPT was not able to find a suitable time, and tried to find the most suitable time of day for a call. e.g. "Morning"',
        booking_failure: 'ConvertGPT was unable to find a suitable callback time after a second attempt',
        booking_success_call: 'ConvertGPT successfully arranged a callback time for the Lead over the phone',
        booking_success_sms: 'ConvertGPT successfully arranged a callback time for the Lead via SMS',
        connect_attempt: 'ConvertGPT attempted to connect the Lead to you',
        connect_success: 'ConvertGPT successfully connected the call to you! 🎉',
        booking_other: "ConvertGPT received a new SMS from Lead",
        sms_booking_appointment_reminder: "ConvertGPT sent a reminder for an upcoming appointment",
        sms_booking_post_followup: "ConvertGPT sent a post-appointment follow up",
        sms_booking_bump_1: "ConvertGPT sent a nurture follow-up",
        sms_booking_bump_2: "ConvertGPT sent another nurture follow-up",
        sms_booking_bump_3: "ConvertGPT sent another nurture follow",
        booking_retarget: "ConvertGPT received an SMS response from the Lead to one of our nurture sequences",
        cc_attempt_check: "ConvertGPT performed a check to determine if the Lead was able to connect to you",
        otn_action_application_taken: "Application Taken confirmation was sent to the Lead.",
        "retarget_conservative-start": "ConvertGPT started a new nurture sequence, and will nurture over the next few months.",
        "retarget_conservative-2": "ConvertGPT sent the next message in the nurture sequence.",
        "retarget_conservative-3": "ConvertGPT sent the next message in the nurture sequence.",
        "retarget_conservative-final": "ConvertGPT sent the final (breakup) message in the nurture sequence.",
        "retarget_moderate-start": "ConvertGPT started a new nurture sequence, and will nurture over the next few weeks.",
        "retarget_moderate-2": "ConvertGPT sent the next message in the nurture sequence.",
        "retarget_moderate-3": "ConvertGPT sent the next message in the nurture sequence.",
        "retarget_moderate-final": "ConvertGPT sent the final (breakup) message in the nurture sequence.",
        "retarget_agressive-start": "ConvertGPT started a new nurture sequence, and will nurture over the next few days.",
        "retarget_agressive-2": "ConvertGPT sent the next message in the nurture sequence.",
        "retarget_agressive-3": "ConvertGPT sent the next message in the nurture sequence.",
        "retarget_agressive-final": "ConvertGPT sent the final (breakup) message in the nurture sequence."
    },
    reminder_description: {
        sms_booking_bump_1: "ConvertGPT will send next follow-up in nurture sequence",
        sms_booking_bump_2: "ConvertGPT will send another follow-up in nurture sequence",
        sms_booking_bump_3: "ConvertGPT will send another follow-up in nurture sequence",
        sms_booking_appointment_reminder: "ConvertGPT will send a reminder 1 hour before your upcoming appointment",
        sms_booking_post_followup: "ConvertGPT will send a post-appointment follow up",
        cc_attempt_check: 'ConvertGPT will perform a check to determine if the Lead was able to connect to you',
        "retarget_conservatives-start": "ConvertGPT will begin a new nurture sequence, and will nurture over the next few months.",
        default: "ConvertGPT will reach out to the Lead"
    },
    method: {
        call: "📞 via Call",
        sms: "📲 via SMS",
        reminder: "🎯 via Retarget",
        email: "📩 via Email"
    },
    script: {
    // booking_success: "What "
    }
}
  
const infoSectionStrings = {
    car_finance_lead: [
    {
        title: "🚗 Vehicle",
        sections: [[
        {
            title: "Vehicle Type",
            value: "vehicle_type"
        },
        {
            title: "Vehicle Use",
            value: "vehicle_use"
        },
        {
            title: "Found Vehicle?",
            value: "vehicle_found"
        }
        ],[
        {
            title: "Loan Amount",
            value: "loan_amount",
            format: "dollar"
        },
        {
            title: "Existing Vehicle?",
            value: "existing_vehicle"
        }
        ]]
    },
    {
        title: "💲 Finance",
        sections: [[
        {
            title: "Looking For Finance?",
            value: "looking_for_finance"
        },
        {
            title: "Previous Finance History?",
            value: "previous_finance"
        },
        {
            title: "Credit Situation",
            value: "credit_situation"
        }
        ]]
    },
    {
        title: "💰 Income",
        sections: [[
        {
            title: "Monthly Income",
            value: "monthly_income",
            format: "dollar"
        },
        {
            title: "Proof Of Income?",
            value: "proof_of_income"
        },
        {
            title: "Earning This For...",
            value: "income_length"
        }
        ]]
    },
    {
        title: "💼 Employment",
        sections: [[
        {
            title: "Employment Status",
            value: "employment_status"
        },
        {
            title: "Company Name",
            value: "company_name"
        },
        {
            title: "Job Title",
            value: "job_title"
        }
        ]]
    },
    {
        title: "🏠 Residency & Living",
        sections: [[
        {
            title: "Citizen Or Perm. Resident?",
            value: "residency_status"
        },
        {
            title: "Own Or Rent?",
            value: "own_or_rent"
        },
        {
            title: "Monthly Rent/Mortgage",
            value: "monthly_mortgage"
        }
        ],[
        {
            title: "Address",
            value: "address_formatted"
        }
        ]]
    }
    ],
    debt_consolidation_lead: [
    {
        title: "💰 Debt Information",
        sections: [[
        {
            title: "Debt Amount",
            value: "debt_amount",
            format: "dollar"
        },
        {
            title: "Majority of Debt",
            value: "debt_type"
        }
        ],[
        {
            title: "Purpose of Enquiry",
            value: "enquiry_type"
        },
        {
            title: "Months behind",
            value: "debt_arrears"
        }
        ]]
    },
    {
        title: "💲 Finance",
        sections: [[
        {
            title: "Struggling with Payments?",
            value: "struggle_payments"
        },
        {
            title: "Previous Bankruptcy?",
            value: "previous_bankruptcy"
        },
        {
            title: "Credit Situation",
            value: "credit_situation"
        }
        ]]
    },
    {
        title: "💼 Employment",
        sections: [[
        {
            title: "Employment Status",
            value: "debt_employment"
        },
        {
            title: "Employment Length",
            value: "income_length"
        }
        ]]
    },
    {
        title: "🏠 Residency & Living",
        sections: [[
        {
            title: "Citizen Or Perm. Resident?",
            value: "residency_status"
        },
        {
            title: "Own Or Rent?",
            value: "own_or_rent"
        }
        ],[
        {
            title: "Address",
            value: "address_formatted"
        }
        ]]
    }
    ]
};

// ------------ END STRINGS ---------------

const handleForgotPassword = async(event) => {
    const resetRes = await window.convertEngine.handleForgotPassword(event);

    console.info('CE: Forgot pass triggered')

    let title = "",
        body = "",
        className = "";

    // define the toast 
    if (resetRes.isErr) {
        console.error('CE:ERR - An error occured with the Password reset handler, this really shouldnt happen:', resetRes.val);
        body = "Password reset attempt failed. If this persists, please contact us."
        className = "warning"
        title = "🤔 Uh oh!"
    } else {
        body = resetRes.val?.message;
        className = "primary"
        title = "😁 Success!"
    }

    // return it
    return window.convertEngine.createToastAlert(
        title, 
        body, 
        className
    );

}

const handleLoginFunctions = () => {
    const form = document.getElementById("login_form");
    if (!form) return null;

    form.addEventListener("submit", window.convertEngine.handleLogin);

    const forgotPwForm = document.getElementById("forgot_pw_form");
    if (!forgotPwForm) return null;
    
    forgotPwForm.addEventListener("submit", handleForgotPassword)

    return null;
}

const workflowStageInfoById = async(lead, id) => {
    const workflow = await window.convertEngine.retrieveWorkflowDataForProject(lead.project_id);

    if (workflow.isErr) {
        console.warn('CE:WARN - An error occured retriving workflow stage by ID:', workflow.val);
        return null;
    }

    const workflowConfig = workflow.val;

    const workflowIdFromStage = id.split("_")[0];

    if (!workflowIdFromStage) return null;

    const foundWorkflow = workflowConfig.find(config => config.id === workflowIdFromStage);

    if (!foundWorkflow) return null;

    const workflowStage = foundWorkflow.stages?.find(stage => stage.id === id);

    return workflowStage || null;
}

const workflowConfigByProject = async(lead) => {
    const workflow = await window.convertEngine.retrieveWorkflowDataForProject(lead.project_id);

    if (workflow.isErr) {
        console.warn('CE:WARN - An error occured retriving workflow stage by ID:', workflow.val);
        return null;
    }

    return workflow.val;
}
const uniqueAssociationsByCompany = async(companyId) => {
    const associations = await window.convertEngine.retrieveUserAssociationsForCompany(companyId);

    if (associations.isErr) {
        console.warn('CE:WARN - An error occured retriving associations by company ID:', associations.val);
        return null;
    }

    return associations.val;
}

const handleAssignLead = async(
    el,
    userId = false,
    reload = false
) => {
    // first, we attempt to identify the target el (if there is one)
    const targetEl = el?.hasAttribute('data-ce_lead_id') ? 
            el :
            el.closest('[data-ce_lead_id]');

    const lead = targetEl ? 
        targetEl.getAttribute('data-ce_lead_id') :
        window.convertEngine.state.lead?.id;
    const project = targetEl ? 
        targetEl.getAttribute('data-ce_project_id') :
        window.convertEngine.state.lead?.project_id;

    const user = userId || window.convertEngine.user.id;

    const assignRes = await window.convertEngine.assignLeadToUserById(project, lead, user);

    if (assignRes.isErr) {
        console.warn('CE:ERR - An error occured when assigning Lead owner:', assignRes);
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!', 
            "We weren't able to assign this Lead to you. If this persists please let us know",
            "danger")
    } else {
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Lead assigned successfully!'
        )
        if (reload) {
            setTimeout(() => {
                return window.location.reload();
            }, 2500)
        }
    }
    
    return null;
};handleAssignLead;

const handleNewActivityForUser = async(event) => {
    event.preventDefault();
    
    const target = event.target;

    
    const leadId = target.getAttribute('ce_lead_id')
    const projectId = target.getAttribute('ce_project_id')
    
    const formData = new FormData(event.target);
    const dataObj = Object.fromEntries(formData.entries());
    const { activityType, activityNote } = dataObj;
    
    if (!activityType) return null;

    
    // check for where we have to enforce a note being added to an activity
    const noteRequiredActivityTypes = ["note", "task"];
    if (noteRequiredActivityTypes.includes(activityType) && !activityNote?.length) {
        return window.convertEngine.createToastAlert(
            '📝 Note required',
            'You must add a note to this type of activity',
            'warning'
            );
        }
        
    window.convertEngine.handleActionButtonState(target, 'activity', 'start');

    const activityRes = await window.convertEngine.addInteractionActivityTolead(
        leadId,
        projectId,
        { note: activityNote, type: activityType }
    );

    if (activityRes.isErr) {
        console.warn('CE:ERR - An error occured when adding activity:', activityRes);
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!', 
            "We weren't able to add your activity. If this persists please let us know",
            "danger")
        window.convertEngine.handleActionButtonState(target, 'activity', 'fail')
    } else {
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Activity added successfully.'
        )
        window.convertEngine.handleActionButtonState(target, 'activity', 'success')
        setTimeout(() => {
            return window.location.reload();
        }, 2500)
    }

    return null;
};handleNewActivityForUser;

const handleCreateNewlead = async(event) => {
    
    event.preventDefault()

    const newLeadInfoContainerEl = document.getElementById('create_new_lead-new_lead_info_container');
   
    newLeadInfoContainerEl.classList.add('hide')

    const data = new FormData(event.target);
    const dataObj = Object.fromEntries(data.entries());
    const { name, email, phone, project, value } = dataObj;

    if (!name || !email || !phone || !project || !value) {
        return window.convertEngine.createToastAlert(
            '⚠️ Missing required fields', 
            'A required field is missing, please make sure to fill out name, email, phone, value, and project.', 
            'danger'
        );  
    }

    // if UTM source is empty, remove it
    if (!dataObj.utm_source?.length) delete dataObj.utm_source;

    // remove the project ID from the request body
    delete dataObj.project;

    const newLead = await window.convertEngine.createNewLead(project, dataObj);

    let title,
        body,
        style;

    if (newLead.isErr) {
        console.warn('CE:WARN - An error occured when attempting to create new lead:', newLead.val);
        title = '⛔️ Lead creation failed';
        body = 'An error occured when attempting to create lead: ' + newLead.val;
        style = 'danger';
    } else {
        const lead = newLead.val;

        title = "😁 Lead created successfully";
        body = "Your new Lead has been created successfully and is available in your Lead CRM"
        style = "success";

        replaceInnerHtmlByEl(
            document.getElementById('create_new_lead-new_lead_info_body'),
            `<a href="/app/leads/profile.html?i=${lead.id}&p=${lead.project_id}" target="_blank">
                <button class="btn btn-success">
                    👤 View Lead profile
                </button>
            </a>`
        );
        newLeadInfoContainerEl.classList.remove('hide');
    }

    return window.convertEngine.createToastAlert(
        title,
        body,
        style
    );
};handleCreateNewlead;

const handleCreateNewLeadFunctions = () => {

    const user = window.convertEngine.user;

    if (!user?.id) {
        console.info('CE: No valid user here, returning early');
        return null;
    };

    const associations = user.associations;

    const validBackfillAssociations = associations
    .map(assoc => assoc.projects
        .filter(proj => proj.id.includes('ce_backfill_lead') || proj.id.includes('ce_custom_lead'))
        .map(proj => proj.id)
        ).flat();

    const createFormBackfillSelectOptionsEl = document.getElementById('ce_create_lead_project_options_select');
    if (!validBackfillAssociations.length) {
        replaceInnerHtmlByEl(
            createFormBackfillSelectOptionsEl,
            `<option selected>
                ⛔️ No backfill projects found.
            </option>`
        );
    } else {
        const transposeBackfillProjectOpts = option => `
            <option value"${option}">
                ${option}
            </option>
        `;

        const optsHtml = validBackfillAssociations
        .map(opt => transposeBackfillProjectOpts(opt))
        .join("");
        replaceInnerHtmlByEl(
            createFormBackfillSelectOptionsEl,
            optsHtml
        );
    }

    return null;
}


// LEAD CRM 
const transposeLeadStatusPill = async(lead) => {
    const workflow = await workflowConfigByProject(lead);
    const workflowStage = lead.workflow_stage_id ? 
        await workflowStageInfoById(lead, lead.workflow_stage_id) :
        null;

    const formedWorkflowStage = workflowStage || { title: "None", id: "" };

    const projectWorkflow = workflow?.[0] || {};

    const style = formedWorkflowStage?.style || "primary";
    return `
    <div class="btn-group ce_lead_stage_btn_group">
        <span class="badge dropdown-toggle mb-2 bg-${style}" 
            type="button" 
            data-bs-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            data-ce_lead_stage_id="${lead.workflow_stage_id || ""}"
            data-ce_lead_stage_btn
            >${formedWorkflowStage.title}</span>
        <div class="dropdown-menu">
        <div class="me-1 px-2 text-dark">
            <h6 class="fs--0">Update Lead stage:</h6>
        </div>
        <div class="dropdown-divider"></div>
        ${projectWorkflow?.stages?.map(stage => `
            <a
                class="dropdown-item d-flex"
                href="#!"
                data-ce_lead_id="${lead.id}"
                data-ce_project_id="${lead.project_id}"
                data-ce_workflow_id="${projectWorkflow.id}"
                data-ce_workflow_stage="${stage.id}"
                onclick="updateLeadWorkflowStage(this, false, true, null, 'list')"
            >
            <span class="badge me-1 py-2 mx-2 
                    badge-subtle-${stage.style || "primary"}">
                ${stage.title}
            </span>
            ${stage.actions?.length ? `
                <div class="text-secondary px-2 pt-1">
                    <i class="fas fa-bolt fs--2" data-fa-transform="shrink-2"></i>
                </div>
            ` : ''}
            </a>
        `).join("")}
        </div>
    </div>
    `
}
const transposeLeadOwnerPill = async(lead) => {
    const user = await window.convertEngine.retrievePublicUserInfoById(lead.lead_owner_id);
    let formedUser = user.isOk ? user.val : { id: "" };
    const style = user.isOk ? "secondary" : "warning"
    return `
        <span class="badge bg-${style}" 
            data-ce_lead_owner_id="${formedUser.id}"
            data-ce_lead_owner_btn
        >
            ${formedUser.user?.name || "Unassigned"}
        </span>`
};
const transposeLeadLatestActivity = lead => {
    const activityHistory = lead?.activity_history?.reverse() || []
    if (!activityHistory?.length) return '';

    let activityWithNote = activityHistory.find(item => item.note?.length);

    if (!activityWithNote) return '';

    // type="button" 

    return `
        <div class="ce_crm_list_lead_activity_note" data-bs-toggle="tooltip" data-bs-placement="right" title="${activityWithNote.note}">
            ${activityWithNote.note}
        </div>
    `;
}
const retrieveUniqueWorkflows = async() => {
    const projects = window.convertEngine.retrieveProjectsFromAssociations();

    const workflows = await Promise.all(
        projects.map(proj => workflowConfigByProject({ project_id: proj }))
    );

    const flattenedWorkflows = workflows?.flat() || []
    // parse to remove any duplicates caused by multiple projects under 1 company
    const uniqueWorkflows = [...new Map(flattenedWorkflows.map(v => [v.id, v])).values()];
    return uniqueWorkflows || [];
}
const retrieveUniqueUserAssociationsFromCompanies = async() => {
    const userAssociations = window.convertEngine.user?.associations;

    if (!userAssociations) return null;

    const associations = await Promise.all(
        userAssociations
        .filter(x => x.company_id !== "*")
        .map(assoc => uniqueAssociationsByCompany(assoc.company_id))
    );

    const flattenedAssociations = associations?.flat() || []
    // parse to remove any duplicates caused by multiple projects under 1 company
    const uniqueAssociations = [...new Map(flattenedAssociations
        .map(v => [v, v])).values()].filter(x => x !== null);
    return uniqueAssociations || [];
};
const handleWorkflowStageActionsUi = (
    optionEl,
    opts,
    type,
    actions = []
) => {

    const flatActions = actions?.map(action => action.actions)?.flat();

    const promptAction = flatActions.find(action => action.type === "sequence_prompt")
    if (promptAction) {
        console.log('IS SEQUENCE PROMPT', promptAction);
        handleWorkflowAction(promptAction);
    }

    switch (type) {
        case 'card': {
            const kanbanEl = document.getElementById('ce_workflow_stage_container');
            if (!kanbanEl) return null;
            const leadCard = kanbanEl.querySelector(`[data-ce_lead_id="${opts?.leadId}"]`);
            if (!leadCard) return null;

            // update the leadCard wf stage id
            leadCard.setAttribute('data-ce_workflow_stage_id', opts?.workflowStageId)
            const requestId = Date.now();
            if (actions.length) {
                let insertHtml = `
                    <div 
                        class="ce_wf_action_indicator position-absolute end-0 top-0 me-1 mt-1" 
                        data-ce_action_request_id="${requestId}"
                    >
                        <span class="fa fa-cog fa-spin text-secondary fs-1"></span>
                    </div>
                `;
                leadCard.querySelector('.ce_lead_card_footer').innerHTML+= insertHtml;
                const actionIndicator = leadCard.querySelector(`.ce_wf_action_indicator[data-ce_action_request_id="${requestId}"]`);
                setTimeout(() => {
                    actionIndicator.innerHTML = '<span class="fas fa-check-circle text-success fs-1"></span>'
                    setTimeout(() => actionIndicator.remove(), 1500);
                }, 1500);
            };

            break;
        };
        case 'list': {

            const leadId = opts?.leadId || optionEl.getAttribute('data-ce_lead_id');

            const listEl = document.getElementById('lead_crm_table_body');
            if (!listEl) return null;
            const leadItem = listEl.querySelector(`[data-ce_lead_id="${leadId}"]`);
            if (!leadItem) return null;
            const stageIndicatorEl = leadItem.querySelector('.ce_lead_stage_btn_group');
            if (!stageIndicatorEl) return null;

            if (actions.length) {

                const element = document.createElement("div");
                element.className = "ce_wf_action_indicator";
                element.innerHTML = '<span class="fa fa-cog fa-spin text-secondary"></span>';

                stageIndicatorEl.appendChild(element);
                const actionIndicator = stageIndicatorEl.querySelector('.ce_wf_action_indicator');

                setTimeout(() => {
                    actionIndicator.innerHTML = '<span class="fas fa-check-circle text-success"></span>'
                    setTimeout(() => actionIndicator.remove(), 1500);
                }, 1500);
            }

            break;
        };
        case 'profile': {
            console.log('profile')
            break;
        };
        default: {
            console.warn('CE: Unknown UI type for workflow stage actions:', type)
        }
    }
    return null;
};
const applyBulkActionsToSelectedListItems = async() => {

    const bulkActionOptionActionEl = document.getElementById('lead_crm_table_bulk_action_option_action');
    const bulkActionOptionValueEl = document.getElementById('lead_crm_table_bulk_action_option_select');

    const actionTypeEl = bulkActionOptionActionEl
    .options[bulkActionOptionActionEl.selectedIndex] || null,
            actionValueEl = bulkActionOptionValueEl
            .options[bulkActionOptionValueEl.selectedIndex] || null;

    if (!actionTypeEl?.value || !actionValueEl?.value) {
        console.info('CE: No value for one of the required bulk action fields')
    }

    const listOptionSelectsChecked = document.querySelectorAll('[data-ce_lead_bulk_checkbox]:checked');

    if (!listOptionSelectsChecked?.length) {
        console.warn('CE: No leads were selected for bulk action, this shouldnt happen')
        return window.convertEngine.createToastAlert(
            '🤔 Cannot apply actions',
            'Hmm, it looks like no Leads are selected, select a few Leads and try again. If this persists, contact us.',
            'warning'
        )
    };

    console.info('CE: Applying bulk actions to %s Leads...', listOptionSelectsChecked?.length)
    window.convertEngine.createToastAlert(
        '⚡️ Applying bulk actions',
        `Applying bulk actions to ${listOptionSelectsChecked?.length} Leads...`,
        'primary'
    )


    const selectedLeadArr = Array.from(listOptionSelectsChecked)
    try {
        await Promise.all(
            selectedLeadArr.map(async(lead) => {
                const leadId = lead.getAttribute('data-ce_lead_id'),
                    projectId = lead.getAttribute('data-ce_project_id');
                switch (actionTypeEl.value) {
                    case 'stage': {
                        const assignRes = await window.convertEngine
                        .updateWorkflowStageForLead(
                            leadId, 
                            actionValueEl.value, 
                            projectId
                        )
                        if (assignRes.isErr) {
                            window.convertEngine.createToastAlert(
                                '⛔️ Could not assign Lead',
                                'An error occured when assigning Lead to user. If this persists, contact us. Lead ID:', + leadId,
                                'danger'
                            );
                            return null;
                        };

                        const leadTableRowEl = lead.closest('tr[data-ce_lead_id]');
                        if (!leadTableRowEl) return null;
                        const leadStageBtnEl = leadTableRowEl
                        .querySelector('td .ce_lead_stage_btn_group [data-ce_lead_stage_btn]');
                        // retrieve the attributes to replace
                        const workflowStageName = actionValueEl.getAttribute('data-ce_workflow_stage_name');
                        const workflowStageStyle = actionValueEl.getAttribute('data-ce_workflow_stage_style');
                        // update the user elements on the table
                        leadStageBtnEl.innerText = workflowStageName
                        leadStageBtnEl.className = "badge dropdown-toggle mb-2 bg-" + workflowStageStyle;
                        leadStageBtnEl.setAttribute('data-ce_lead_stage_id', actionValueEl.value)

                        handleWorkflowStageActionsUi(
                            null,
                            { leadId, projectId },
                            'list',
                            assignRes.val?.actions
                        )

                        return null;
                    };
                    case 'assign': {
                        const assignRes = await window.convertEngine.assignLeadToUserById(
                            projectId, 
                            leadId, 
                            actionValueEl.value
                        )
                        if (assignRes.isErr) {
                            window.convertEngine.createToastAlert(
                                '⛔️ Could not assign Lead',
                                'An error occured when assigning Lead to user. If this persists, contact us. Lead ID:', + leadId,
                                'danger'
                            )
                        };

                        const leadTableRowEl = lead.closest('tr[data-ce_lead_id]');
                        if (!leadTableRowEl) return null;
                        const leadOwnerBtnEl = leadTableRowEl.querySelector('td [data-ce_lead_owner_btn]');

                        const userName = actionValueEl.getAttribute('data-ce_user_name');

                        leadOwnerBtnEl.innerText = userName;
                        leadOwnerBtnEl.className = "badge bg-secondary";
                            
                        return null;
                    };
                    default: {
                        console.info('CE: Unsupported bulk action:', actionTypeEl.value)
                    };
                };
                return null;
            })
        )
    } catch(err) {
        console.error('CE:ERR - An error occured applying bulk actions:', err.message);
        window.convertEngine.createToastAlert(
            '⛔️ Cannot apply bulk actions',
            'An error occured when applying bulk actions. If this persists, contact us.',
            'danger'
        )
    }

    window.convertEngine.createToastAlert(
        '✅ Bulk actions applied!',
        `Actions applied successfully!`,
        'success'
    )

    return null;
};applyBulkActionsToSelectedListItems;
const renderOptionsForBulkActionSelect = async(el) => {

    const bulkActionOptionSelectEl = document.getElementById("lead_crm_table_bulk_action_option_select");

    const value = el.value;

    replaceInnerHtmlByEl(
        bulkActionOptionSelectEl,
        "<option selected disabled>--- ⏳ Loading... ---</option>"
    );

    switch(value) {
        case 'stage': {
            // retrieve all unique workflows
            const uniqueWorkflows = await retrieveUniqueWorkflows();

            const workflowStageHtml = uniqueWorkflows.length ?
                    uniqueWorkflows.map(workflow => `
                        ${uniqueWorkflows.length > 1 ? `
                        <option disabled>-- ${workflow.title}</option>
                        ` : ''}
                        ${workflow.stages?.map(stage => `
                            <option value="${stage.id}" 
                                data-ce_workflow_stage_name="${stage.title}"
                                data-ce_workflow_stage_style="${stage.style || "primary"}"
                            >${stage.title}</option>
                        `)}
                    `).join("") : '<option disabled selected>-- NO STAGES FOUND --</option>';

            replaceInnerHtmlByEl(
                bulkActionOptionSelectEl,
                workflowStageHtml
            );

            if (!uniqueWorkflows?.length) {
                bulkActionOptionSelectEl.disabled = true;
            } else {
                bulkActionOptionSelectEl.disabled = false;
            }

            break;
        };
        case 'assign': {
            const uniqueAssociations = await retrieveUniqueUserAssociationsFromCompanies();
            const currentUser = window.convertEngine?.user?.id;
            const sortedUniqueUsers = uniqueAssociations?.sort((a) => a === currentUser ? -1 : 1);

            const associationOptHtml = uniqueAssociations.length ?
                await Promise.all(
                    sortedUniqueUsers.map(async(assoc) => {
                        const user = await window.convertEngine.retrievePublicUserInfoById(assoc);
                        let formedUser = user.isOk ? user.val : { id: null };
                        const isCurrentUser = formedUser?.id === currentUser;
                        return formedUser.id ? `
                            <option value="${formedUser.id}"
                                data-ce_user_name="${formedUser.user?.name}"
                            >
                                ${isCurrentUser ? "Me" : (formedUser.user?.name || "UNKNOWN USER")}
                            </option>
                        ` : ''
                    })
            ) : [ '<option disabled selected>-- NO USERS FOUND --</option>' ];

            replaceInnerHtmlByEl(
                bulkActionOptionSelectEl,
                associationOptHtml.join("")
            );

            if (!uniqueAssociations?.length) {
                bulkActionOptionSelectEl.disabled = true;
            } else {
                bulkActionOptionSelectEl.disabled = false;
            }

            break;
        };
        default: {
            console.info('CE: Unsupported option for bulk action:', value);
        };
    }

    return null;

};renderOptionsForBulkActionSelect;

const bulkSelectFromList = (el, tableItem = false) => {

    const listOptionSelects = document.querySelectorAll('[data-ce_lead_bulk_checkbox]');

    if (!listOptionSelects?.length) return null;

    const crmBulkTableOptionsContainerEl = document.getElementById('lead_crm_table_bulk_action');
    const crmQueryOptionsContainerEl = document.getElementById('lead_crm_table_query_options');

    const elIsChecked = el.checked;

    const setCheck = (item, bool, type) => {
        const itemEl = item;
        const typeOfVal = type || "checked";
        itemEl[typeOfVal] = bool;
        return null;
    }

    const updateDisplayOfContainers = (type) => {
        if (type === "bulk_actions") {
            crmBulkTableOptionsContainerEl.classList.remove('hide')
            crmQueryOptionsContainerEl.classList.add('hide')
        } else {
            // TODO: Also reset the container
            crmBulkTableOptionsContainerEl.classList.add('hide')
            crmQueryOptionsContainerEl.classList.remove('hide')
        };
        return null;
    }

    // if only a table item
    if (tableItem) {
        const listOptionSelectsChecked = document.querySelectorAll('[data-ce_lead_bulk_checkbox]:checked')
        const listOptionCheckMaster = document.querySelector('[data-ce_lead_bulk_checkbox_master]')
        // check for length of checked
        if (listOptionSelectsChecked?.length) {
            // if some, set the main on to indeterminate
            // show the bulk action div
            updateDisplayOfContainers('bulk_actions')
            // length is same as current options
            if (listOptionSelectsChecked?.length === listOptionSelects.length) {
                listOptionCheckMaster.checked = true;
            } else {
                listOptionCheckMaster.indeterminate = true;
            }
            


        } else {
            updateDisplayOfContainers('query')
            listOptionCheckMaster.indeterminate = false;
        }

        // if none, uncheck the main one too
        // also remove the bulk action div


        return null;
    };

    // if checked
    if (elIsChecked) {
        listOptionSelects.forEach(item => setCheck(item, true))
        updateDisplayOfContainers('bulk_actions')
    } else {
        listOptionSelects.forEach(item => setCheck(item, false))
        updateDisplayOfContainers('query')
    }
    // check all els on the page
    // make sure div is shown

    // otherwise
    // uncheck all else on the page
    // make sure to hide div

    // return
    return null;
};bulkSelectFromList;

const transposeLeadForList = async(lead) => {
    const leadList = window.convertEngine.state.crmList;
    if (!leadList) {
        console.warn('CE: Attempting to add Lead to list which does not exist')
        return null;
    }

    const { name, phone, email } = lead.event?.user;
    const profileLink = `
    <a href="/app/leads/profile.html?i=${lead.id}&p=${lead.project_id}" target="_blank">
        <span class="fs--1 fw-bold text-primary">
            ${name}
        </span>
    </a>`

    const status = await transposeLeadStatusPill(lead)

    const owner = await transposeLeadOwnerPill(lead)

    const parsedUnix = new Date(lead.timestamp);
    const initCreatedDate = parsedUnix.toLocaleString('en-US', {
        dateStyle: "short",
        // timeStyle: "short"
    });

    const latestActivity = transposeLeadLatestActivity(lead)

    const bulkSelect = `
    <div class="form-check mb-0">
        <input 
            class="form-check-input" 
            type="checkbox" 
            data-ce_lead_id="${lead.id}"
            data-ce_project_id="${lead.project_id}"
            data-ce_lead_bulk_checkbox
            onclick="bulkSelectFromList(this, true)"
        />
    </div>
    `

    return leadList.add({
        name: profileLink,
        ce_lead_id: lead.id,
        ce_project_id: lead.project_id,
        phone,
        email,
        owner,
        stage: status,
        created: lead.timestamp,
        created_verbose: initCreatedDate,
        profileLink,
        latest: latestActivity,
        bulk_select: bulkSelect
      });
};
const retrieveAndTransposeListLeads = async(
    queryType, // type of query to run
    clear = false, // if true, clears all current Leads & state
    isScroll = false, // whether this event is triggered from a scroll
    id, // optional ID value which can be used for more complex queries
) => {

    // set the initial retrieving state and retrieve initial leads
    window.convertEngine.state.retrivingleadList = true;

    let queryObj;

    const leadList = window.convertEngine.state.crmList;
    if (!leadList) {
        console.warn('CE:WARN - Attempting to retrieve leads for list, but no list is found');
        return null;
    }

    const typeOfQuery = queryType || window.convertEngine.state?.crmQuery || 'created:desc';
    const valueOfQuery = isScroll ? window.convertEngine.state.crmQueryVal : null;

    if (window.convertEngine.state?.crmQuery !== typeOfQuery) {
        console.info('CE: Current query is not stored in state, storing...')
        window.convertEngine.setState('crmQuery', typeOfQuery, true)
    };
    // if non-scroll query, check if the state val matches this val, if not, store it
    if (!isScroll && window.convertEngine.state?.crmQueryVal !== id) {
        console.info('CE: Current query val is not stored in state, storing...')
        window.convertEngine.setState('crmQueryVal', id, true)
    }

    const limit = window.convertEngine.state?.crmQueryListLimit || 25;

    let isFilteredQuery = false; // will be used for logic later for displaying 0 results
    // structure the API query
    switch (typeOfQuery) {
        case 'me:desc': {
            queryObj = {
                query: "owner",
                value: window.convertEngine.user.id,
                limit
            }
            isFilteredQuery = true;
            break;
        };
        case 'owner_id:desc': {
            queryObj = {
                query: "owner",
                value: id || valueOfQuery,
                limit
            }
            isFilteredQuery = true;
            break;
        }
        case 'owner:unassigned': {
            queryObj = {
                query: "owner",
                value: "unassigned",
                limit
            }
            isFilteredQuery = true;
            break;
        };
        case 'created:desc': {
            queryObj = {
                query: "created",
                value: "desc",
                limit
            }
            break;
        };
        case 'workflow_id:desc': {
            queryObj = {
                query: "workflow_id",
                value: id || valueOfQuery,
                limit
            }
            isFilteredQuery = true;
            break;
        }
        case 'workflow_stage:desc': {
            queryObj = {
                query: "workflow_stage",
                value: id || valueOfQuery,
                limit
            }
            isFilteredQuery = true;
            break;
        }
        default: {
            console.warn('CE: Invalid Lead retrieval query heard:', queryType, { clear, isScroll, id })
        }
    };
    
    if (clear) {
        // reset the paginationKeys, as this is a new list
        window.convertEngine.state.pagination_all = {};
        leadList.clear();
        // update the view of any buttons in the UI to match
        const listBtnEls = document.querySelectorAll(`[data-ce_crm_get_btn="list"][value="${typeOfQuery}"]`) || [];
        listBtnEls.forEach(el => { // check all of the buttons
            el.setAttribute('checked', 'checked');
            return null;
        });
        // store state on the window, so that reload always goes to current search
        const qsp = new URLSearchParams(window.location.search);
        qsp.set('q', typeOfQuery)
        qsp.set('v', id || null)
        const relativePath = window.location.pathname + '?' + qsp.toString();
        window.history.pushState(null, '', relativePath)
    }

    const retrievalLoader = `
    <tr class="text-align-center" id="crm_loading_tr">
        <td colspan="100%"> <span class="fs-0 fw-bold">⏳ Loading...</span></td>
    </tr>
    `;
    replaceInnerHtmlByEl(
        document.getElementById('lead_crm_table_body'),
        retrievalLoader,
        true
    );

    // retrieve the leads
    const retrievedLeads = await window.convertEngine.retrieveLeads(queryObj, 'all');

    // handle the error if no Leads
    const crmTableLoaderEl = document.getElementById('crm_loading_tr');

    // if an error
    if (retrievedLeads.isErr) {
        const errHtml = `
            <td colspan="100%">
            <span class="fs-0 fw-bold">
                🤔 Uh oh, an error occured: ${retrievedLeads.val || "we're not sure what happened"}
            </span>
            </td>
        `;
        replaceInnerHtmlByEl(
            crmTableLoaderEl,
            errHtml
        ); 
        return window.convertEngine.createToastAlert(
            '⚠️ Lead retrieval error', 
            'Cannot retrieve Leads: ' + (retrievedLeads.val || "An error occured."), 
            'danger'
        );
    }
    // stage query dropdown
    const crmQueryStageListDropdownEl = document.getElementById('l_crm_list_query_workflow_options');
    const crmQueryStageListRadioLabelEl = document.querySelector('[for="l_crm_list_radio_type-workflow_stage"]');
    // owner query dropdowns
    const crmQueryOwnerListDropdownEl = document.getElementById('l_crm_list_query_owner_id_options');
    const crmQueryOwnerListRadioLabelEl = document.querySelector('[for="l_crm_list_radio_type-owner_id"]');
    // logic for buttons
    if (typeOfQuery.includes("workflow_stage") && id && crmQueryStageListDropdownEl) {
        console.info('CE: Is workflow-stage query')
        const crmQueryStageListDropdownItem = crmQueryStageListDropdownEl.querySelector(`[data-ce_workflow_stage="${id}"]`);
        if (crmQueryStageListDropdownItem) {
            const title = crmQueryStageListDropdownItem.getAttribute('data-ce_workflow_stage_title');
            const style = crmQueryStageListDropdownItem.getAttribute('data-ce_workflow_stage_style');
            // set the button style to match the stage!
            crmQueryStageListRadioLabelEl.className = "btn dropdown-toggle btn-sm px-2 btn-" + (style || "primary")
            crmQueryStageListRadioLabelEl.innerText = title;
            // check the main radio
            document.getElementById('l_crm_list_radio_type-workflow_stage')?.setAttribute('checked', 'checked')
        }

        // clear any logic from owner query buttons
        crmQueryOwnerListRadioLabelEl.className = "btn dropdown-toggle btn-sm px-2 btn-primary";
        if (crmQueryOwnerListRadioLabelEl.innerText !== "Owner") {
            crmQueryOwnerListRadioLabelEl.innerText = "Owner"
        }
    } else if (typeOfQuery.includes("workflow") && id && crmQueryStageListDropdownEl) {
        // TODO: Handle workflow ID querys
    } else if (typeOfQuery.includes("owner_id:desc")) {
        console.info('CE: Is workflow-stage query')
        const crmQueryOwnerListDropdownItem = crmQueryOwnerListDropdownEl.querySelector(`[data-ce_lead_owner_id="${id}"]`);
        if (crmQueryOwnerListDropdownItem) {
            const title = crmQueryOwnerListDropdownItem.getAttribute('data-ce_lead_owner_name');
            // set the button style to match the stage!
            crmQueryOwnerListRadioLabelEl.className = "btn dropdown-toggle btn-sm px-2 btn-secondary"
            crmQueryOwnerListRadioLabelEl.innerText = title;
            // check the main radio
            document.getElementById('l_crm_list_radio_type-owner_id')?.setAttribute('checked', 'checked')
        }

        // clear any logic from stage query buttons
        crmQueryStageListRadioLabelEl.className = "btn dropdown-toggle btn-sm px-2 btn-primary";
        if (crmQueryStageListRadioLabelEl.innerText !== "Stage") {
            // need to change this back to stage
            crmQueryStageListRadioLabelEl.innerText = "Stage"
        }
    } else {
        // otherwise clear logic from both
        // stage
        crmQueryStageListRadioLabelEl.className = "btn dropdown-toggle btn-sm px-2 btn-primary";
        if (crmQueryStageListRadioLabelEl.innerText !== "Stage") {
            // need to change this back to stage
            crmQueryStageListRadioLabelEl.innerText = "Stage"
        }
        // owner
        crmQueryOwnerListRadioLabelEl.className = "btn dropdown-toggle btn-sm px-2 btn-primary";
        if (crmQueryOwnerListRadioLabelEl.innerText !== "Owner") {
            crmQueryOwnerListRadioLabelEl.innerText = "Owner"
        }
    }

    // if no leads found
    if (!retrievedLeads.val?.length) {
        // crmTableLoaderEl.innerHTML = ;
        const noLeadHtml = `
            <td colspan="100%">
            <span class="fs-0 fw-bold">
                💡 ${(isFilteredQuery || isScroll) ? 
                    `No Leads found${isScroll ? "!" : " for this filter!"}` :
                    "You don't have any Leads yet!"}
            </span>
            </td>
        `;
        replaceInnerHtmlByEl(
            crmTableLoaderEl,
            noLeadHtml
        );
        return isScroll ? window.convertEngine.createToastAlert(
            'ℹ️ No more Leads found', 
            "It looks like you've reached the end of your Leads!", 
            'primary'
        ) : null;
    } else if (isScroll) {
        // if isScroll, let's show the user that Leads have been retrieved
        window.convertEngine.createToastAlert(
            'ℹ️ Leads retrieved!', 
            null,
            'primary'
        ); 
    }
    // else transpose into the tableList

    const leads = retrievedLeads.val;

    // map through workflow data prior to transposing leads
    try {
        // retrieve unique project IDs
        const allProjectsFromLeads = (leads.map(lead => lead.project_id) || []).flat(),
            uniqueProjectsFromLeads = [...new Map(allProjectsFromLeads.map(v => [v, v])).values()];
        // retrieve unique user IDs
        const uniqueUserAssociations = await retrieveUniqueUserAssociationsFromCompanies()

        // greedy retrieve this information & store it in session state

        await Promise.all([
            Promise.all(uniqueProjectsFromLeads
                .map(
                    async(project) => workflowConfigByProject({ project_id: project })
                )
            ),
            Promise.all(uniqueUserAssociations
                .map(
                    async(assoc) => window.convertEngine.retrievePublicUserInfoById(assoc)
                )
            )
        ]) 
    } catch(err) {
        console.error('CE:ERR - An error occured when pre-fetching worfklow & user info:', err.message)
    }

    // once greedy data retrieval is completed, move on to transposing the table
    crmTableLoaderEl.remove();

    // map lead info to the List
    await Promise.all(
        leads.map(async(lead) => transposeLeadForList(lead))
    );
    // await Promise.all(leads.map(async(lead => transposeLead(lead))))

    // determine the default sort function
    switch (typeOfQuery) {
        case 'unknown': {
            // do nothing
            break;
        }
        default: {
            console.info('Sorting by ts desc')
            leadList.sort('created', { order: 'desc' });
        }
    }

    // remove the retrieving state
    window.convertEngine.state.retrivingleadList = false;
    return null;

};
const transposeWorkflowOptionsForQuerySelector = async() => {
    
    const uniqueWorkflows = await retrieveUniqueWorkflows()

    if (!uniqueWorkflows.length) {
        console.info('no workflows')
        const elsToRemove = document.querySelectorAll('#l_crm_list_radio_type-workflow_stage, [for="l_crm_list_radio_type-workflow_stage"], #l_crm_list_query_workflow_options')//.remove()
        elsToRemove?.forEach(el => el.remove())
    }

    const crmQueryStageListDropdownEl = document.getElementById('l_crm_list_query_workflow_options')
    const workflowHtmlForQueryButton = uniqueWorkflows.map((workflow, i) => {
        const workFlowHtml = `
        ${uniqueWorkflows.length > 1 ? `
            ${i > 0 ? '<div class="dropdown-divider"></div>' : ''}
            <a class="dropdown-item ${i > 0 ? "mt-2" : ""}" 
                href="#!"
                data-ce_workflow_id="${workflow.id}"
                data-ce_workflow_title="${workflow.title}"
                onclick="retrieveAndTransposeListLeads('workflow_id:desc', true, false, '${workflow.id}')"
            >
                <span class="fs--1 fw-bold mb-1">
                    ${workflow.title}
                </span>
            </a>
            ` : ''}
            ${workflow.stages?.map(stage => `
            <a class="dropdown-item" 
                href="#!"
                data-ce_workflow_id="${workflow.id}"
                data-ce_workflow_stage="${stage.id}"
                data-ce_workflow_stage_title="${stage.title}"
                data-ce_workflow_stage_style="${stage.style || "primary"}"
                onclick="retrieveAndTransposeListLeads('workflow_stage:desc', true, false, '${stage.id}')"
            >
                <span class="badge me-1 py-2 mx-2 badge-subtle-${stage.style || "primary"}">
                    ${stage.title}
                </span>
            </a>
            `).join("")}
        `;
        return workFlowHtml;
    }).join((""))

    replaceInnerHtmlByEl(
        crmQueryStageListDropdownEl,
        workflowHtmlForQueryButton
    );

    return null;
}
const transposeOwnerOptionsForQuerySelector = async() => {
    
    const uniqueUsers = await retrieveUniqueUserAssociationsFromCompanies();

    if (!uniqueUsers.length) {
        console.info('no users')
        const elsToRemove = document.querySelectorAll('#l_crm_list_radio_type-owner_id, [for="l_crm_list_radio_type-owner_id"], #l_crm_list_query_owner_id_options')
        // for now, don't remove them
        console.info(elsToRemove)
        // elsToRemove?.forEach(el => el.remove())
    }

    const currentUser = window.convertEngine?.user?.id;
    const sortedUniqueUsers = uniqueUsers?.sort((a) => a === currentUser ? -1 : 1);

    const crmQueryOwnerListDropdownEl = document.getElementById('l_crm_list_query_owner_id_options')
    const ownerHtmlForQueryButton = await Promise.all(sortedUniqueUsers.map(async(user, i) => {
        const userVal = await window.convertEngine.retrievePublicUserInfoById(user)
        if (userVal.isErr) {
            window.convertEngine.createToastAlert(
                '⛔️ Could not retrieve user info',
                'Could not retrieve user info by id: ' + user
            );
            return ""
        }
        const userFromVal = userVal.val;
        const isCurrentUser = userFromVal?.id === currentUser;
        const ownerHtml = `
        <a class="dropdown-item ${i > 0 ? "mt-2" : ""}" 
            href="#!"
            data-ce_lead_owner_id="${userFromVal.id}"
            data-ce_lead_owner_name="${userFromVal.user?.name}"
            onclick="retrieveAndTransposeListLeads('owner_id:desc', true, false, '${userFromVal.id}')"
            >
            <span class="fs--1 fw-bold mb-1">
                ${isCurrentUser ? "Me" : userFromVal.user?.name}
            </span>
        </a>`;
        return ownerHtml;
    }))//.join((""))

    replaceInnerHtmlByEl(
        crmQueryOwnerListDropdownEl,
        ownerHtmlForQueryButton.join("")
    );

    return null;
}

const updateSelectedProjectForCrmViews = (proj = null) => {


    window.convertEngine.setLocalState('project_id', proj, true);

    window.location.reload();

    return null;
    
};updateSelectedProjectForCrmViews;
const transposeProjectOptionsForProjectSelector = () => {
    
    const projects = window.convertEngine.retrieveProjectsFromAssociations();

    const projectsForDropdown = projects?.map(proj => `<a class="dropdown-item" href="#" onclick="updateSelectedProjectForCrmViews('${proj}')">${proj}</a>`).join("");

    const html = `
        ${projectsForDropdown}
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#" onclick="updateSelectedProjectForCrmViews()">All Projects</a>`


    replaceInnerHtmlByEl(
        document.getElementById('ce_crm_list_select_project_list'),
        html
    );

    // if there is more than one project, unhide the selector, otherwise keep it hidden
    if (projects?.length > 1) {
        document.getElementById('ce_crm_list_select_project_container')?.classList?.remove('hide');
    };

    return null
}

const transposeOwnerOptionsForDropdown = async(
    targetEl
) => {
    const uniqueUsers = await retrieveUniqueUserAssociationsFromCompanies();

    if (!uniqueUsers.length) {
        return null;
    }

    const currentUser = window.convertEngine?.user?.id;
    const sortedUniqueUsers = uniqueUsers?.sort((a) => a === currentUser ? -1 : 1);

    const ownerHtmlForDropdown = await Promise.all(sortedUniqueUsers.map(async(user, i) => {
        const userVal = await window.convertEngine.retrievePublicUserInfoById(user)
        if (userVal.isErr) {
            window.convertEngine.createToastAlert(
                '⛔️ Could not retrieve user info',
                'Could not retrieve user info by id: ' + user
            );
            return ""
        }
        const userFromVal = userVal.val;
        const isCurrentUser = userFromVal?.id === currentUser;
        const ownerHtml = `
        <a class="dropdown-item ${i > 0 ? "mt-2" : ""}" 
            href="#!"
            data-ce_lead_owner_id="${userFromVal.id}"
            data-ce_lead_owner_name="${userFromVal.user?.name}"
            onclick="handleAssignLead(this, '${userFromVal.id}', true)"
            >
            <span class="fs--1 fw-bold mb-1">
                ${isCurrentUser ? "Me" : userFromVal.user?.name}
            </span>
        </a>`;
        return ownerHtml;
    }))//.join((""))

    replaceInnerHtmlByEl(
        targetEl,
        ownerHtmlForDropdown.join(""),
        true
    );
    return null;
};
const handleLeadCrmFunctions = async() => {

    const listOptions = {
        valueNames: [
            'name', 'phone', 'email', 'stage', 'owner', 'profileLink','created',
            'latest', 'bulk_select',
            'created_verbose',
            { data: ['ce_lead_id', 'ce_project_id'] }
        ],
        page: 1000,
        pagination: true,
        item: `
            <tr class="id" 
                data-ce_lead_id="1" 
                data-ce_project_id="1"
                data-id="1">
            <td class="bulk_select">
            </td>
            <td class="name">
            </td>
            <td class="phone">
            </td>
            <td class="email"></td>
            <td class="stage"></td>
            <td class="owner"></td>
            <td class="created_verbose"></td>
            <td class="latest"></td>
            <td class="profileLink d-none"></td>
        </tr>`
    }
    // define the CRMList in state
    window.convertEngine.state.crmList = new window.List('lead_crm_table', listOptions)

    document.addEventListener('scroll', async() => {
        let documentHeight = document.body.scrollHeight;
        let currentScroll = window.scrollY + window.innerHeight;
        // When the user is [modifier]px from the bottom, fire the event.
        let modifier = 100; 
        if(currentScroll + modifier > documentHeight) {
            if (window.convertEngine.state.retrivingleadList) return null;

            window.convertEngine.state.retrivingleadList = true;
            window.convertEngine.createToastAlert(
                '⏳ Loading leads...',
                null,
                'primary'
            )
            await retrieveAndTransposeListLeads(null, null, true);
            // window.convertEngine.createToastAlert(
            //     'Leads loaded successfully',
            //     null,
            //     'primary'
            // )
            // add loader to end of list
            // return transposeleads

            return null;
        };
        return null;
    })

    const params = window.convertEngine.getQueryStringParams();

    const crmListUpdateProjectBtn = document.getElementById('ce_crm_list_select_project_btn');
    if (window.convertEngine?.local_state?.project_id) {
        crmListUpdateProjectBtn.innerText = window.convertEngine?.local_state?.project_id;
    };

    retrieveAndTransposeListLeads(
        params.q || null, // queryType
        true, // clear
        false, // is scroll?
        params.v || null // id
    );

    return Promise.all([
        transposeWorkflowOptionsForQuerySelector(),
        transposeOwnerOptionsForQuerySelector(),
        transposeProjectOptionsForProjectSelector()
    ]);
}

const updateLeadWorkflowStage = async(
    optionEl, // div containing data attributes
    reload = true, // if true, will reload page
    updateBtn = false, // if true, will attempt to update parent button
    opts = false,
    type // type of element (card/list/profile)
) => {
    if (!optionEl && !opts) {
        console.warn('CE:WARN - there should have been an el attached to this Workflow Stage request')
        return null;
    };
    const leadId = opts?.leadId || optionEl.getAttribute('data-ce_lead_id');
    // const workflowId = optionEl.getAttribute('data-ce_workflow_id');
    const workflowStageId = opts?.workflowStageId || optionEl.getAttribute('data-ce_workflow_stage');
    const projectId = opts?.projectId || optionEl.getAttribute('data-ce_project_id');

    const updateWorkflowStageRes = await window.convertEngine
    .updateWorkflowStageForLead(leadId, workflowStageId, projectId)

    if (updateWorkflowStageRes.isErr) {
        console.warn('CE:ERR - An error occured when attempting to update Lead Workflow stage:', 
            updateWorkflowStageRes);
        window.convertEngine.createToastAlert(
            '🤔 Unable to update Lead stage', 
            "Could not update Lead workflow stage. If this persists, please contact us.", 
            'danger'
        )
    } else {
        // does this have actions?
        handleWorkflowStageActionsUi(
            optionEl,
            opts,
            type,
            updateWorkflowStageRes.val?.actions
        )

        // say success and reload the page
        window.convertEngine.createToastAlert(
            '😁 Lead status Updated!', 
            'Lead Status has been updated'
        )
        if (reload) {
            setTimeout(() => {
                return window.location.reload();
            }, 2500)
        }

        if (updateBtn) {
            
            const leadStagebtnGroupEl = optionEl.closest('.ce_lead_stage_btn_group'),
            leadStagebtnEl= leadStagebtnGroupEl.querySelector('[data-ce_lead_stage_btn]');

            // TODO: Determine if we need this
            // const workflow = await workflowConfigByProject({ project_id: projectId });
            const workflowStage = await workflowStageInfoById(
                { id: leadId, project_id: projectId }, 
                workflowStageId
            )
    
            const formedWorkflowStage = workflowStage || { title: "None", id: "" };
            
            leadStagebtnEl.innerText = formedWorkflowStage.title;
            
            const style = formedWorkflowStage.style || "primary";
            leadStagebtnEl.className = `badge dropdown-toggle mb-2 bg-${style}`;
        }
    }

    return null;
};
const handleWorkflowLeadCardClickthrough = (el) => {
    if (!el) return null;

    const leadId = el.getAttribute('data-ce_lead_id'),
            projectId = el.getAttribute('data-ce_project_id');

    const url = `/app/leads/profile.html?i=${leadId}&p=${projectId}`

    return window.open(url, '_blank').focus();
};handleWorkflowLeadCardClickthrough;

const transposeLeadForWorkflowBoard = async(lead) => {

    // TODO: This needs to be based on lead.data instead
    const { name, phone, email } = lead.event?.user;

    const owner = await transposeLeadOwnerPill(lead)

    const value = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(lead.value || lead.data?.value || 0);

    const leadCardHtml = `
        <div class="kanban-item" 
            tabindex="0"
            data-ce_workflow_stage_id="${lead.workflow_stage_id}"
            data-ce_lead_id="${lead.id}"
            data-ce_project_id="${lead.project_id}"
            onclick="handleWorkflowLeadCardClickthrough(this)"
        >
        <div class="card kanban-item-card hover-actions-trigger">
        <div class="card-body">
            <div class="position-relative"> </div>
            <p 
                class="mb-1 fs-0 fw-medium font-sans-serif stretched-link"
            >${name}</p>
            <p class="fs--2 mb-1">${phone} • ${email}</p>
            <div class="d-flex ce_lead_card_footer">
            <p class="fs--2 mb-0 pt-1">
                <span class="fw-medium text-600 ms-0">${value}</span>
            </p>
            <div class="mx-2">${owner}</div>
            </div>
        </div>
        </div>
    </div>`

    replaceInnerHtmlByEl(
        document.querySelector(`[data-ce_workflow_stage_id="${lead.workflow_stage_id}"] [data-ce_workflow_stage_column]`),
        leadCardHtml,
        true
    )
}

const retrieveAndTransposeBoardLeads = async(
    queryType, // type of query to run (all or stage)
    clear = false, // if true, clears all current Leads & state
    isScroll = false, // whether this event is triggered from a scroll
    id = null // ID for complex queries
) => {

    const limit = window.convertEngine.state?.crmQueryBoardLimit || 100;

    let queryObj = {};
    let isFilteredQuery = false; // will be used for logic later for displaying 0 results
    // structure the API query
    switch (queryType) {
        case 'created:desc': {
            queryObj = {
                query: "created",
                value: "desc",
                limit
            }
            break;
        };
        case 'workflow_stage:desc': {
            queryObj = {
                query: "workflow_stage",
                value: id,
                limit
            }
            isFilteredQuery = true;
            break;
        };
        default: {
            console.warn('CE: Invalid Lead retrieval query heard:', queryType, { clear, isScroll, id })
        }
    };

       // retrieve the leads
    const retrievedLeads = await window.convertEngine.retrieveLeads(queryObj, id);

    // TODO: Figure out if we need isFilteredQuery for board views?
    console.log(isFilteredQuery)

    if (retrievedLeads.isErr) {
        replaceInnerHtmlByEl(
            document.querySelector(`[data-ce_workflow_stage_id="${id}"] [data-ce_workflow_stage_column]`),
            `<span class="fs-1 text-secondary text-align-center">
                🤔 Uh oh, an error occured: ${retrievedLeads.val || "we're not sure what happened"}
            </span>`
        ); 
        return window.convertEngine.createToastAlert(
            '⚠️ Lead retrieval error', 
            'Cannot retrieve Leads: ' + (retrievedLeads.val || "An error occured."), 
            'danger'
        );
    }

    const leads = retrievedLeads.val;

    if (!leads?.length) {
        // TODO: Do something to handle no Leads being in a view, or can a board just be empty?

    // replaceInnerHtmlByEl(
    //     document.querySelector(
    //         `[data-ce_workflow_stage_id="${id}"] [data-ce_workflow_stage_column]`
    //     ),
    //     `<span class="fs-1 text-secondary text-align-center">
    //         🤔 Uh oh, an error occured: ${retrievedLeads.val || "we're not sure what happened"}
    //     </span>`
    // ); 
    }

    if (clear) {
        replaceInnerHtmlByEl(
            document.querySelector(`[data-ce_workflow_stage_id="${id}"] [data-ce_workflow_stage_column]`),
            ''
        ); 
    }

    // map lead info to the List
    await Promise.all(
        leads.map(async(lead) => transposeLeadForWorkflowBoard(lead))
    );

    return null;
}
const transposeWorkflowStagesAsColumns = workflow => {
    const workflowStateColumnHtml = workflow.stages.map(stage => {
        const style =  `bg-subtle-${stage.style || 'primary'}`;
        console.log(stage.actions, 'ACTIONS')
        const actions = stage.actions;
        let actionEl = actions?.length ? `
        <span 
            class="badge text-warning position-absolute top-0 end-0 px-1 m-1 fs--2"
            data-bs-toggle="tooltip" data-bs-placement="top" title="Stage contains actions"
        ><i class="fas fa-bolt fs--1" data-fa-transform="shrink-1"></i></span>
        `: ''
        return `<div 
                class="kanban-column"
                data-ce_workflow_stage_id="${stage.id}"
            >
            <div class="kanban-column-header position-relative d-block">
                <h5 class="fs-0 mb-0">${stage.title}<span class="text-500 d-none">(...)</span></h5>
                ${actionEl}
                ${stage.description ? `
                    <div class="d-block text-secondary fs--1 mt-1"><span>${stage.description}</span></div>
                `: ''}
            </div>
            <div 
                class="kanban-items-container scrollbar ${style}" 
                tabindex="0"
                data-ce_workflow_stage_column
            >
                <span class="fs-0 text-secondary">Loading...</span>
            </div>
            <div class="kanban-column-footer ${style}">
                <button 
                    class="btn btn-link btn-sm d-block w-100 btn-load-more-workflow text-decoration-none text-600 d-none" 
                    type="button"
                >
                    <span class="fas fa-plus me-2"></span>Load more</button>
            </div>
        </div>`
    }).join("");

    replaceInnerHtmlByEl(
        document.getElementById('ce_workflow_stage_container'),
        workflowStateColumnHtml
    )
    replaceInnerHtmlByEl(
        document.getElementById('ce_workflow_name'),
        workflow.title
    )
    
    
    return null;
}
const handleLeadWorkflowFunctions = async() => {
    // retrieve projects from associations
    const workflows = await retrieveUniqueWorkflows();
    // grab first workflow from projects
    const workflow = workflows[0];
    // set as workflowstate
    window.convertEngine.state.workflow_view = workflow;
    
    // tranpose the columns
    transposeWorkflowStagesAsColumns(workflow)

    // map through workflow data prior to transposing leads
    try {
        // retrieve unique user IDs
        const uniqueUserAssociations = await retrieveUniqueUserAssociationsFromCompanies()

        // greedy retrieve this information & store it in session state
        await Promise.all(uniqueUserAssociations
            .map(
                async(assoc) => window.convertEngine.retrievePublicUserInfoById(assoc)
            )
        )
    } catch(err) {
        console.error('CE:ERR - An error occured when pre-fetching worfklow & user info:', err.message)
    }

    transposeProjectOptionsForProjectSelector()

    // retrieve based on that 
    
    await Promise.all(
        workflow.stages?.map(
            async(stage) => retrieveAndTransposeBoardLeads(
                'workflow_stage:desc', 
                true, 
                false, 
                stage.id
            )
        )
    );
                
                // TODO: add scroll listener for board bodies
                
                
    const crmListUpdateProjectBtn = document.getElementById('ce_crm_list_select_project_btn');
    if (window.convertEngine?.local_state?.project_id) {
        crmListUpdateProjectBtn.innerText = window.convertEngine?.local_state?.project_id;
    } else if (!Object.hasOwn(window.convertEngine?.local_state || {}, 'project_id')) {
        console.info('Project ID on list selector is null, choosing the first one')
        const firstProject = window.convertEngine.retrieveProjectsFromAssociations()?.[0];
        window.convertEngine.setLocalState('project_id', firstProject || null, true);
        crmListUpdateProjectBtn.innerText = firstProject || "All Projects";
    };

    // init drag
    draggableInit();
    // listener for board drop
    window.convertEngine.events.on('workflow_stage_board_drag_drop', e => {
        const target = e.el,
        columnContainer = e.columnContainer

        if (!target) {
            return null;
        }

        const dropContainer = columnContainer.closest('.kanban-column[data-ce_workflow_stage_id]')

        if (!dropContainer) {
            return null;
        };

        const workflowStageId = dropContainer.getAttribute('data-ce_workflow_stage_id'),
                leadWorkflowStageId = target.getAttribute('data-ce_workflow_stage_id'),
                leadId = target.getAttribute('data-ce_lead_id'),
                projectId = target.getAttribute('data-ce_project_id');

        if (leadWorkflowStageId === workflowStageId) {
            // same stage, so should do nothing
            console.log('same ID, cannot change')
            return null;
        } else {
            return updateLeadWorkflowStage(
                null,
                false,
                null,
                {
                    projectId,
                    leadId,
                    workflowStageId 
                },
                'card'
            )
        }
    });

    return null;
}

const formatLeadDataValue = (value, format) => {
    if (!value) return "Unknown"; // have a default value here

    if (!format) return value;

    try {
    switch(format){
        case 'dollar': {
        return new Intl.NumberFormat('en-US', {
            style: "currency",
            currency: "AUD"
        }).format(value)
        }
        default: {
        return value;
        }
    }
    } catch(e) {
    console.warn('ERR: An error occurred when formatting lead data value:', { error: e.message, value, format })
    return value;
    }
}

const profileDetailSectionCard = (
    title,
    body //html
) => {

    return `
    <div class="card mb-3">
        <div class="card-header bg-light">
            <h5 class="mb-0">${title}</h5>
        </div>
        <div class="card-body fs--1">
            ${body}
        </div>
    </div>
    `
}

const transposeCustomSection = (data, serviceType) => {
    const dataEl = document.getElementById('l_profile_content_main');
    const sections = infoSectionStrings[serviceType];

    const html = sections.map(section => {
        const sectionHtml = section.sections?.map(subsection => {
            let div = Math.floor(12 / (12 / subsection.length));
            return `
                <div class="row row-cols-1 row-cols-lg-${div} mb-2">
                ${subsection.map(dp => `
                    <div class="feature col mb-3">
                        <h6><b>${dp?.title}</b></h6>
                        <span>${formatLeadDataValue(data[dp.value], dp.format)}</span>
                    </div>
                    `).join("")}
                </div>
            `
        }).join("") 
        return profileDetailSectionCard(section?.title, sectionHtml)
    }).join("")

    replaceInnerHtmlByEl(
        dataEl,
        html,
        true
    );
    return null;
}
const transposeRawDataSection = (dataObj) => {
    const rawValueHtml = `
    <div class="row row-cols-1 row-cols-lg-1">
        ${Object.keys(dataObj).sort((a,b) => a > b ? 1 : -1).map(key => `<div class="feature col mb-3"><span class="fw-bold mr-1">${key} </span><span>${dataObj[key]}</span></div>`).join("")}
    </div>
    `
    replaceInnerHtmlByEl(
        document.getElementById('l_profile_content_main'),
        profileDetailSectionCard('Lead data', rawValueHtml),
        true
    );

    return null;
}

const transposeCreditHistoryEl = (item) => {

    let header = "";
    const user = item?.user?.name || item?.user;
    header+= `<div class="lead-status-credit-interaction-item-header d-flex">`
    header+= `<div class="col-lg-6 col-12"><h6 class="fs-0 credit-interaction-item-user">`
    header+= `${user}</h6></div>`
    header+= `<div class="col-lg-6 col-12 text-right">`
    header+= `<span class="credit-interaction-item-ts">${item.ts_verbose}</span>`
    header+= `</div></div>`;


    let body = "";

    body+= `<div class="lead-status-credit-interaction-item-body my-1">`
    body+= `<h6 class="fs--1">${item.reason}</h6><span>${item.note}</span>`

    if (item.image_file_url) {
        body+= `<div>
        <a href="${item.image_file_url}" data-gallery="gallery-2">
            <img class="img-fluid rounded" src="${item.image_file_url}" alt="" width="300" />
        </a></div>`;
    }

    body+= `</div>`

    let html = "";

    const dir = item.is_admin ? "out-item" : "in-item"

    html+= `<div class="row lead-status-credit-interaction-item `
    html+= dir
    html+= ` p-2 px-4">`;
    html+= header
    html+= `<hr class="my-1">`
    html+= body
    html+= `</div>`

    // eslint-disable-next-line max-len 
    return html
    
}

const generateHistoryComponentDescription = (history) => {

    switch(history.stage) {
        case 'call_initiated': return callConnectStrings.status_description.call_initiated;
        case 'sms_initiated': return callConnectStrings.status_description.sms_initiated;
        case 'prompt': {
            if (history.method === "call") {
            const res = history.input === "1" ?
                    callConnectStrings.status_description.prompt_call_connect :
                    callConnectStrings.status_description.prompt_call_callback;
            return res
            } else {
            return history.stage;
            }
        };
        case 'booking_attempt': {
            if (history.method === "call") return callConnectStrings.status_description.booking_attempt_call;
            else return callConnectStrings.status_description.booking_attempt_sms;
        }
        case 'booking_attempt_2': {
            if (history.method === "call") return callConnectStrings.status_description.booking_attempt_2_call;
            else return callConnectStrings.status_description.booking_attempt_2_sms;
        }
        case 'booking_success': {
            if (history.method === "call") return callConnectStrings.status_description.booking_success_call;
            else return callConnectStrings.status_description.booking_success_sms;
        }
        default: {
            return callConnectStrings.status_description?.[history.stage] || null
        }
    }
}
const generateHistoryActionCallInfo = data => {
    switch (data.action) {
    case 'call_init': {
        return `<span>📞 Initiated a call to <span class="cc-action-history-highlight">${data.to || "a number"}</span></span>`;
    }
    case 'call_update': {
        return `<span>📞 Progressed conversation to next step</span>`;     
    }
    case 'call_connect_init': {
        return `<span>📞 Dialed client numbers to try and find available operator</span>`;
    }
    case 'call_connect_transfer': {
        return `<span>📞 Call Connect was accepted by <span class="cc-action-history-highlight">${data.to || "a team member"}</span></span>`;
    }
    default: {
        return `<span>${data.action}</spann>`;
    }
    };
}
const generateHistoryActionSmsInfo = data => {
    switch (data.action) {
    default: {
        return `
        <span>📲 Sent an SMS to <span class="cc-action-history-highlight">${data.to || "a number"}</span>:</span>
        <div class="l_cc-instance-datetime-parsed_bubble outgoing p-2 mx-3 my-2 bg-dark">
            <div class="l_cc-datetime-parsed_body px-2">
            <span>${data.text?.replace(/(?:\r\n|\r|\n)/g, '<br>')}</span>
            </div>
        </div>
        `;
    }
    };
}
const generateHistoryActionEmailInfo = data => {
    switch (data.action) {
    default: {
        const inputVal = data.text ? `
        <div class="l_cc-instance-datetime-parsed_bubble outgoing p-2 mx-3 my-2 bg-dark">
            <div class="l_cc-datetime-parsed_body px-2">
            ${data.data?.subject ? `<span class="badge bg-secondary mb-2 fs--2">SUBJECT: ${data.data.subject}</span>` : ''}
            <span>${data.text?.replace(/(?:\r\n|\r|\n)/g, '<br>')}</span>
            </div>
        </div>` : '';
        console.log(data, "DATA IS HERE ON THIS ACTION")
        return `
        <span>📩 Sent an email to <span class="cc-action-history-highlight">${data.emails?.join('</span>, <span class="cc-action-history-highlight">')}</span></span>
        ${inputVal}
        `;
    }
    };
}
const generateHistoryActionAppointmentInfo = data => {
    switch (data.event_type) {
    case 'update': {
        return `<span>⚡️ Updated the Lead's appointment time:
                <div class="l_cc-instance-datetime-parsed_bubble l_cc-appointment-updated_bubble action p-2 mx-3 my-2">
                <div class="l_cc-datetime-parsed_body px-2">
                    <span>📆 ${data.appointment?.user_verbose_datetime} ${data.appointment?.timezone}</span>
                </div>
                </div>`;
    }
    case 'book': {
        return `<span>📆 Booked appointment & sent confirmation email`
    }
    default: {
        return `<span>${data.action}</spann>`;
    }
    };
}
const generateHistoryActionConvertEngineActionInfo = data => {
    switch (data.event_type) {
    case 'cancel_reminder': {
        return `<span>⚡️ Cancelled an upcoming sequence</span>`;
    }
    case 'mark_as_completed': {
        return `<span>✅ Marked the sequence as <span class="cc-action-history-highlight">complete</span></span>`;
    }
    case 'update_status': {
        return `<span>💡 Updated the status of the lead</span>`;
    }
    default: {
        return `<span>${data.action}</span>`;
    }
    };
}
const generateHistoryActionDescription = (action) => {
    switch (action.type) {
        case 'call': return generateHistoryActionCallInfo(action.data);
        case 'sms': return generateHistoryActionSmsInfo(action.data);
        case 'email': return generateHistoryActionEmailInfo(action.data);
        case 'appointment': return generateHistoryActionAppointmentInfo(action.data);
        case 'ce_action': return generateHistoryActionConvertEngineActionInfo(action.data);
        default: {
            return action.type
        }
    }
}
const generateActionLog = actions => {

    if (!actions?.length) return '';

    console.info({ actions }, "ACTIONS ARE HERE")

    const html = `<div class="cc-history-log-action-container pb-2">
    <div class="cc-history-log-action-header mt-2 mb-1 fs--2 text-align-center fw-bold">
        <span>--- ⚡️ Actions ---</span>
    </div>
    ${actions.map(action => `
        <div class="cc-history-log-action fw-bold">
        ${generateHistoryActionDescription(action)}
        </div>
    `).join("")}
    </div>`

    return html;
}
const parseTimeBetween = (timeBetween) => {
    // seconds in...
    // 1m - 60
    // 1h - 3600
    // 1d - 86400

    let timeValue, parsedTime;

    if (timeBetween >= 86400) { // days
    timeValue = "days";
    parsedTime = Math.round(timeBetween / 24 / 60 / 60);
    } else if (timeBetween >= 3600) { // hours
    timeValue = "hours";
    parsedTime = Math.round(timeBetween / 60 / 60);
    } else if (timeBetween >= 60) { // minutes
    timeValue = "minutes";
    parsedTime = Math.round(timeBetween / 60);
    } else { // seconds
    timeValue = "seconds";
    parsedTime = Math.round(timeBetween);
    };
    // if a single minute later,
    if (parsedTime === 1) {
    timeValue = timeValue.slice(0, -1);
    }

    return { timeValue, parsedTime };
}
const transposeHistoryComponentInput = (
    historyComponent
) => {

    const {
        method, // the type of input e.g. "sms" or "email"
        input
    } = historyComponent;

    switch (method) {
        case 'email': {
            const parsedInputForEmail = input?.replace(/(?:\r\n|\r|\n)/g, '<br>')
            return parsedInputForEmail;
        };
        case 'sms': {
            return `💬 "${input}"`;
        };
        default: {
            return `💬 "${input}"`;
        };
    };

}
const transposeHistoryComponent = (historyArr, history, i) => {
    const emoji = history.method === "call" ? "📞" : "📲";
    // const stage = history.stage;
    // const index = i+1;

    const description = history?.title || generateHistoryComponentDescription(history);
    const subtitle = history.description;

    const timeBetween = historyArr[i-1] ? 
            -(historyArr[i-1]?.timestamp - historyArr[i].timestamp) 
            : null;

    const { parsedTime, timeValue } = parseTimeBetween(timeBetween);

    let relativeTimeString;// = `<span>${parsedTime} ${timeValue} later</span>`

    const parsedUnix = new Date(history.timestamp * 1000);
    if (i === 0) {
    
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        };
    
        const initDate = parsedUnix.toLocaleDateString('en-US', options);
    
        relativeTimeString = `<span>ConvertGPT triggered: ${initDate}</span>`
    } else {
        const initShortDate = parsedUnix.toLocaleDateString('en-US', {
            year: "numeric",
            weekday: "short",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        });
        // May 9, 2023, 3:35 PM
        relativeTimeString = `<span>${initShortDate} - ${parsedTime} ${timeValue} later</span>`
    }

    const input = history.input;
    let inputSpan = '';
    
    const inputDescriptionMap = {
        sms: "📲 User said via SMS:",
        email: "📩 User said via Email:",
        call: "📞 User said via Call:"
    }

    if (input) {
      inputSpan = `
      <div class="l_cc-instance-input-parsed_container mt-2">
        <div class="cc-history-log-input-header text-align-end">
          <span class="fw-bold">${inputDescriptionMap[history.method] || "🗣 User said:"}</span>
        </div>
        <div class="l_cc-instance-datetime-parsed_bubble p-2 mx-3 my-2 float-right">
          <span class="l_cc-datetime-parsed_body px-2">${transposeHistoryComponentInput(history)}</span>
        </div>
      </div>
      `
    };

    const historyStepHeader = (subtitle || description) ?
            `<div class="cc-history-log-header p-2">
                <div class="px-2">
                ${description ? 
                    `<span class="cc-history-log-title d-block fw-bold fs-0">
                    <span class="cc-history-log-title-emoji">${emoji}</span> 
                    ${description}</span>` : 
                    ''}
                </div>
                ${subtitle ? 
                    `<span class="cc-history-log-description d-block">${subtitle}</span>` 
                    : ""}
            </div>
            ` : ''

    const html = `
    <div class="row cc-history-interstitial my-3 fs--2 text-align-center fw-bold">
      ${relativeTimeString}
    </div>
    <div class="row cc-history-log my-2">
        ${historyStepHeader}
        ${inputSpan}
        ${generateActionLog(history.actions)}
    </div>`  

    return html;
}
const transposeHistoryUpcomingComponent = (instance) => {
    const timeBetween = instance.reminder_ts - (Date.now() / 1000);
    const emoji = "⏲";

    const reminderAction = instance.reminder_action;

    const { parsedTime, timeValue } = parseTimeBetween(timeBetween);

    const relativeTimeString = parsedTime < 0 ?
    '<span>Sending shortly</span>' :
    `<span>in ${parsedTime} ${timeValue}</span>`;

    const reminderDescription = callConnectStrings.reminder_description?.[reminderAction]
        || generateHistoryComponentDescription({ stage: reminderAction }) 
        || callConnectStrings.reminder_description?.default;

    const html = `
        <div class="row cc-history-interstitial my-3 fs--2 text-align-center fw-bold">
            <span>------ ⚡️ ${relativeTimeString} ------</span>
        </div>
        <div class="row cc-history-log cc-reminder-log py-2 px-1 my-2">
            <div class="col-12 fw-bold">
                <span>${emoji} - ${reminderDescription}</span>
            </div>
            <div class="col-12 text-align-center">
                <button class="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#ce_modal-cancel_upcoming_automation">
                    ❌ Cancel upcoming sequence
                </button>
            </div>
        </div>`;

    return html;
}

const addNurtureOptions = async(instance) => {
    const serviceType = instance.service_type;

    const params = window.convertEngine.getQueryStringParams();

    const allowAdminSequences = params.m === "1"

    let options = (nurtureEntrances?.[serviceType] || [])
    .filter(x => allowAdminSequences ? true : !x.admin);

    if (!options) {
        console.warn('WARN: No nurture options found for service type:', serviceType);
        return null;
    };

    replaceInnerHtmlByEl(
        document.getElementById('cc-nurture-action-description'),
        options[0]?.description
    );

    const optionsSelect = document.getElementById('cc-nurture-action')
    // add internal/management options
    optionsSelect.options[0]?.remove();
    options.forEach(option => {
        const l = optionsSelect.options?.length
        const optionEl = new Option(option.title, option.action);
        optionEl.setAttribute('data-config', JSON.stringify(option))
        optionsSelect.options[l] = optionEl;
        return null;
    })

    optionsSelect.addEventListener('change', e => {
        const val = e.target.value;

        const selectedOption = optionsSelect.options[optionsSelect.selectedIndex];
        
        const config = selectedOption.getAttribute('data-config');

        if (!config?.length) {
            console.warn('WARN: No nurture option was found for value:', val);
            return null;
        };

        const parsedConfig = JSON.parse(config);

        if (parsedConfig.one_time_notification) {
            document.getElementById('cc-otn-disclaimer').classList.remove('hide');
            document.getElementById('cc-reminder-configs').classList.add('hide');
        } else {
            document.getElementById('cc-otn-disclaimer').classList.add('hide');
            document.getElementById('cc-reminder-configs').classList.remove('hide');
        }
        // if there is a default reminder in the entrance config, set it as well
        if (parsedConfig.default_reminder) {
            document.getElementById('cc-nurture-unit').value = parsedConfig.default_reminder.unit
            document.getElementById('cc-nurture-period').value = parsedConfig.default_reminder.period
        } else { // if not, set it back to default
            document.getElementById('cc-nurture-unit').value = 1
            document.getElementById('cc-nurture-period').value = "minutes"
        }

        replaceInnerHtmlByEl(
            document.getElementById('cc-nurture-action-description'),
            parsedConfig.description
        );
        return null;
    })

    // retrieve custom scenarios for users
    const companiesForScenarios = window.convertEngine.user?.associations?.map(x => x.company_id);
    console.info('CE: Retrieving scenarios for companies:', companiesForScenarios)
    const scenarios = await Promise.all(
        companiesForScenarios.map(
            async(companyId) => window.convertEngine.apiQuery(
                `company/${companyId}/engine/get-company-scenarios`,
                'GET'
            )
        )
    )
    
    const filteredScenarios = scenarios
    .filter(x => x.isOk)
    .map(x => x.val?.scenarios || [])
    .flat();
    
    filteredScenarios.forEach(option => {
        const l = optionsSelect.options?.length
        const optionEl = new Option(option.title, option.action);
        optionEl.setAttribute('data-config', JSON.stringify(option))
        optionsSelect.options[l] = optionEl;
        return null;
    })

    if (!optionsSelect.selectedIndex && optionsSelect.options[0]) {
        optionsSelect.options[0].selected = true;
        optionsSelect.dispatchEvent(new Event('change'));
    }

    return null;
}

const initNurtureAutomation = async() => {

    const instance = window.convertEngine.state.instance;

    if (!instance) return this.convertEngine.createToastAlert(
        '⚠️ Automation Error', 
        'Cannot start sequence, please refresh and try again', 
        'danger'
        );
  
    const serviceType = instance?.service_type;
  
    const options = nurtureEntrances?.[serviceType];
  
    if (!options) {
      console.warn('WARN: No nurture options found for service type:', serviceType);
      return null;
    };
  
    const optionsSelect = document.getElementById('cc-nurture-action')
  
    const action = optionsSelect.value;
  
    const selectedOption = optionsSelect.options[optionsSelect.selectedIndex];

    const config = selectedOption.getAttribute('data-config');

    if (!config?.length) {
        console.warn('WARN: No nurture option was found for value:', action);
        return null;
    };

    const parsedConfig = JSON.parse(config);

    const unit = parsedConfig.one_time_notification
                ? 0
                : document.getElementById('cc-nurture-unit').value;
    const period = parsedConfig.one_time_notification
                ? 'minutes'
                : document.getElementById('cc-nurture-period').value;
  
    const data = {
      project_id: instance.project_id,
      id: instance.id,
      reminder: {
        unit,
        period,
        action
      },
      one_time_notification: parsedConfig.one_time_notification || false
    };
  
    const res = await window.convertEngine.startSequenceForInstance(data);

    if (res.isErr) {
        console.warn('CE:ERR - An error occured when attempting to start new sequence:', res);
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!', 
            "We weren't able to start your automation sequence. If this persists please let us know",
            "danger")
    } else {
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Your new automation was started successfully! 🤖'
        )
        setTimeout(() => {
            return window.location.reload();
        }, 2500)
    }
  
    return null
  };initNurtureAutomation;

const cancelScheduleReminder = async() => {

    const instance = window.convertEngine.state.instance;

    if (!instance) return this.convertEngine.createToastAlert(
        '⚠️ Automation Error', 
        'Cannot stop sequence, please refresh and try again. If this persists please let us know', 
        'danger'
    );

    const res = await window.convertEngine.cancelSequenceForInstance(
        instance.id, 
        instance.project_id
    );

    if (res.isErr) {
        console.warn('CE:ERR - An error occured when attempting to start new sequence:', res);
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!', 
            "We weren't able to end your automation sequence. If this persists please let us know",
            "danger"
            )
    } else {
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Automation was cancelled successfully! 🤖'
        )
        setTimeout(() => {
            return window.location.reload();
        }, 2500)
    }

    return null
};cancelScheduleReminder;

const createEngineInstance = async() => {

    const lead = window.convertEngine.state.lead;

    if (!lead) return this.convertEngine.createToastAlert(
        '⚠️ Automation Error', 
        'Cannot create instance, please refresh and try again. If this persists please let us know', 
        'danger'
    );

    const res = await window.convertEngine.createEngineInstance(
        lead.id, 
        lead.project_id
    );

    if (res.isErr) {
        console.warn('CE:ERR - An error occured when attempting to create new instance:', res);
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!', 
            "We weren't able to create a new ConvertGPT instance. If this persists please let us know",
            "danger"
            )
    } else {
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Automation instance was created successfully! 🤖 Reloading...'
        )
        setTimeout(() => {
            return window.location.reload();
        }, 5000);
    }

    return null
};createEngineInstance;

const renderActivityTimestamp = timestamp => {
    const current = Date.now();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;

    var elapsed = current - timestamp;

    if (elapsed < msPerMinute) {
         return 'Just now'  
    }
    else if (elapsed < msPerHour) {
        let minuteValue = Math.round(elapsed/msPerMinute)
         return minuteValue + ' ' + (minuteValue > 1 ? 'minutes' : 'minute') + ' ago';   
    }
    else if (elapsed < msPerDay ) {
        let hourValue = Math.round(elapsed/msPerHour)
         return hourValue + ' ' + ((hourValue > 1 ? 'hours' : 'hour'))  + ' ago';   
    }
    else {
        const parsedUnix = new Date(timestamp);
        const initCreatedDate = parsedUnix.toLocaleDateString('en-US', {
            year: "numeric",
            weekday: "short",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        });
        return initCreatedDate;  
    }
}

const renderLeadActivityHistoryItemLeadOwner = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">🙋‍♀️</span>
                </div>
            </div>
        </div>
        <div class="notification-body">
            <p class="mb-1"><strong>${activityUser.name}</strong> was assigned as <strong>Lead owner</strong></p>
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItemWorkflowAction = async(item) => {

    // TODO: Do the to/from in here
    const data = item.data;

    const actionsLength = data?.actions?.length

    const actionPhrasing = actionsLength === 1
        ? "action</strong> was"
        : "actions</strong> were";

    const actionTemplates = {
        sequence_update: "Lead was added to sequence",
        sequence_cancel: "Lead was removed from sequence",
        tag_add: "Tag/s were added to Lead",
        tag_remove: "Tag/s were removed from Lead",
        sequence_prompt_all: "User was prompted to add Lead to a new Sequence"
    }

    const actionsHtml = data?.actions?.map(action => {
        const templateKey = `${action.type}_${action.action || "all"}`;
        const message = actionTemplates[templateKey] || "An unknown action";
        return "<li>" + message + "</li>";
    }).join("");

    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">🤖</span>
                </div>
            </div>
        </div>
        <div class="notification-body">
            <p class="mb-1"><strong>${actionsLength} ${actionPhrasing} triggered automatically:</p>
            <ul class="mb-1">
                ${actionsHtml}
            </ul>
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>
        </div>
    </a>`
}

const renderLeadActivityHistoryItemLeadStage = async(item, lead) => {

    // TODO: Do the to/from in here
    const data = item.data;
    const workflow = await window.convertEngine.retrieveWorkflowDataForProject(lead.project_id);
    const workflowToId = data.workflow_stage_to?.split("_")[0] || null;
    const workflowToName = workflow?.isOk && workflowToId ?
            workflow.val?.find(x => x.id === workflowToId)
            ?.stages?.find(y => y.id === data.workflow_stage_to)
            : { title: "Unknown" }
    const workflowFromId = data.workflow_stage_from?.split("_")[0] || null
    const workflowFromName = workflow?.isOk && workflowFromId ?
    workflow.val?.find(x => x.id === workflowFromId)
    ?.stages?.find(y => y.id === data.workflow_stage_from)
    : { title: "Unassigned"}
    const activityUser = item.user;

    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">⏭</span>
                </div>
            </div>
        </div>
        <div class="notification-body">
            <p class="mb-1">Lead Stage was updated from <strong>${workflowFromName?.title || "Unknown"}</strong>
                to
                <strong>${workflowToName?.title || "Unknown"}</strong> by ${activityUser.name}</p>
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>`
}
const renderLeadActivityHistoryItemCallEvent = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    let note = item.note? `
    <div class="pr-1">
        <div class="alert alert-warning px-2 p-1 mb-2">
            <span>${item.note}</span>
        </div>
    </div>` : ''
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">📞</span>
                </div>
            </div>
        </div>
        <div class="notification-body w-100">
            <p class="mb-1"><strong>${activityUser.name}</strong> called the Lead${item.note ? " with note:": ""}</p>
            ${note}
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItemSmsEvent = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    let note = item.note? `
    <div class="pr-1">
        <div class="alert alert-warning px-2 p-1 mb-2">
            <span>${item.note}</span>
        </div>
    </div>` : ''
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">📲</span>
                </div>
            </div>
        </div>
        <div class="notification-body w-100">
            <p class="mb-1">
            <strong>${activityUser.name}</strong> 
            sent an SMS to the Lead${item.note ? " with note:": ""}</p>
            ${note}
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItemEmailEvent = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    let note = item.note? `
    <div class="pr-1">
        <div class="alert alert-warning px-2 p-1 mb-2">
            <span>${item.note}</span>
        </div>
    </div>` : ''
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">📧</span>
                </div>
            </div>
        </div>
        <div class="notification-body w-100">
            <p class="mb-1">
            <strong>${activityUser.name}</strong> 
            sent an email to the Lead${item.note ? " with note:": ""}</p>
            ${note}
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItemMeetingEvent = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    let note = item.note? `
    <div class="pr-1">
        <div class="alert alert-warning px-2 p-1 mb-2">
            <span>${item.note}</span>
        </div>
    </div>` : ''
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">🤝</span>
                </div>
            </div>
        </div>
        <div class="notification-body w-100">
            <p class="mb-1"><strong>${activityUser.name}</strong> recorded a meeting${item.note ? " with note:": ""}</p>
            ${note}
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItemTaskEvent = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    let note = item.note? `
    <div class="pr-1">
        <div class="alert alert-warning px-2 p-1 mb-2">
            <span>${item.note}</span>
        </div>
    </div>` : ''
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">⚡️</span>
                </div>
            </div>
        </div>
        <div class="notification-body w-100">
            <p class="mb-1"><strong>${activityUser.name}</strong> performed an activity${item.note ? " with note:": ""}</p>
            ${note}
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItemTaskNote = async(item) => {

    // TODO: Do the to/from in here
    const activityUser = item.user;
    let note = item.note? `
    <div class="pr-1">
        <div class="alert alert-warning px-2 p-1 mb-2">
            <span>${item.note}</span>
        </div>
    </div>` : ''
    return `
    <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300" href="#!">
        <div class="notification-avatar">
            <div class="avatar avatar-m me-3">
                <div class="avatar-emoji rounded-circle ">
                    <span role="img" aria-label="Emoji">⚡️</span>
                </div>
            </div>
        </div>
        <div class="notification-body w-100">
            <p class="mb-1"><strong>${activityUser.name}</strong> added a note:</p>
            ${note}
            <span class="notification-time">${renderActivityTimestamp(item.timestamp)}</span>

        </div>
    </a>
    `
}
const renderLeadActivityHistoryItem = async(item, lead) => {
    switch(item.type) {
        case 'lead_owner': return renderLeadActivityHistoryItemLeadOwner(item, lead);
        case 'workflow_stage': return renderLeadActivityHistoryItemLeadStage(item, lead);
        case 'workflow_action': return renderLeadActivityHistoryItemWorkflowAction(item);
        case 'call': return renderLeadActivityHistoryItemCallEvent(item, lead);
        case 'sms': return renderLeadActivityHistoryItemSmsEvent(item, lead);
        case 'email': return renderLeadActivityHistoryItemEmailEvent(item, lead);
        case 'meeting': return renderLeadActivityHistoryItemMeetingEvent(item, lead);
        case 'task': return renderLeadActivityHistoryItemTaskEvent(item, lead);
        case 'note': return renderLeadActivityHistoryItemTaskNote(item, lead);
        default: {
            console.info('CE: unsupported activity item type:', item.type);
            return ''; // return an empty string
        }
    }
}
const transposeLeadActivityHistory = async(lead) => {
    const activityHistory = lead.activity_history;

    
    // const mappedActivityHtml = activityHistory
    // ?.reverse() // flip to newest first
    // .map(item => renderLeadActivityHistoryItem(item))
    // .join("");
    const activityBodyEl = document.getElementById('l_activity_container');

    if (activityBodyEl) {
        const mappedActivityHtml = await Promise.all(
            activityHistory.map(async(item) => renderLeadActivityHistoryItem(item, lead))
        );//.reverse().join("")

        replaceInnerHtmlByEl(
            activityBodyEl,
            mappedActivityHtml.reverse().join("")
        );
    }
    return null;
}

// updateWorkflowStageForLead

const retrieveAndTransposeInteractionHistory = async(lead) => {
    const ccRes = await window.convertEngine.getInteractionsByLeadId(
        lead.cc_id, 
        lead.id, 
        lead.project_id
    );

    if (ccRes.isErr) {
        console.warn('CE:ERR - An error occured when attempting to retrieve CC instance:', ccRes);
        window.convertEngine.createToastAlert(
            '⚠️ Error', 
            "An error occured. If this persists please let us know", 
            'danger'
        )
        return null;
    }

    // unhide the start automation button
    document.getElementById('l_cc_action-start_automation').classList.remove('hide')

    const instance = ccRes.val;
    
    const ccPillText = callConnectStrings.status[instance.status] || `🤖 ${instance.status}`;
    // find the pills text in the strings or in the CC instances
    const ccMethodVal = instance.completed_method 
    || instance.history[instance.history?.length-1]?.method 
    || null;
    const ccMethodPill = callConnectStrings.method[ccMethodVal] || null;

    replaceInnerHtmlByEl(
        document.getElementById('l_cc_status_pill'),
        ccPillText
    );

    if (!ccMethodPill) {
      // remove this one if there is no method found for the pill
      document.getElementById('l_cc_method_pill').remove();
    }

    // checks for appointment, if it finds one, unhides the div and populates it
    if (instance.appointment?.verbose_datetime) {
        console.info('has appointment')
        // an appointment is on here, show it
        replaceInnerHtmlByEl(
            document.getElementById('l_ce_appointment_booking_time'),
            instance.appointment?.verbose_datetime
        );
        replaceInnerHtmlByEl(
            document.getElementById('l_cc-datetime-parsed_body'),
            `💬 "${instance.appointment?.parsed_datetime || ""}"`
        );
        document.getElementById('l_ce_appointment_booking_card').classList.remove('hide');
    } // otherwise, leave it hidden

    const historyBodyEl = document.getElementById('l_cc-instance-history_accordion_body-main')
    if (instance.history?.length) {
        console.info('has history')
        const historyHtml = instance.history.map((history, i) => transposeHistoryComponent(instance.history, history, i)).join("");
        replaceInnerHtmlByEl(
            historyBodyEl,
            historyHtml
        );
    } else {
        console.info('CE: No ConvertGPT history')
        const subCta = instance.reminder_ts > 0 && instance.reminder_action !== "REMINDER_COMPLETE" ?
            "" :
            `<span class="my-2">Click '⚡️ Start new Automation' below to start your first sequence</span>`;
        replaceInnerHtmlByEl(
            historyBodyEl,
            `<div class="row cc-history-interstitial my-3 fs--2 text-align-center fw-bold">
                <span class="fs--1 fw-bold">💡 ConvertGPT has not performed any actions yet</span>
                ${subCta}
            </div>`
        );
        // 
    }

    if (instance.reminder_ts > 0 && instance.reminder_action !== "REMINDER_COMPLETED") {
        console.info('Is a pending reminder here')

        const reminderHtml = transposeHistoryUpcomingComponent(instance)

        replaceInnerHtmlByEl(
            historyBodyEl,
            reminderHtml,
            true
        );
        
    }

    // scroll to the bottom, so the newest events are shown
    historyBodyEl.parentNode.scrollTop = historyBodyEl.parentNode.scrollHeight;


    addNurtureOptions(instance);
    return null;

}

const getLeadStatus = (lead) => {
    // credited lead
    if (lead.is_invalid) {
        return {
            status: "credited",
            title: "Credited",
            state: "dark"
        }
    };
    // overflow lead
    if (lead.is_overflow) {
        return {
            status: "overflow",
            title: "Unallocated",
            state: "danger"
        }
    };
    // pending credit
    if (("invalid" in lead && !lead.is_invalid)) {
        if (lead.invalid.status === "rejected") {
            return {
                status: "rejected",
                title: "Credit rejected",
                state: "dark",
                invalid_status: lead.invalid?.status
            }            
        }

        return {
            status: "pending",
            title: "Pending assessment",
            state: "info",
            invalid_status: lead.invalid?.status
        }
    }

    return {
        status: "valid",
        title: "Qualified",
        state: "success"
    }
}

const transposeLeadTags = (tags = []) => {
    if (!tags.length) {
        return `
        <div class="my-3 fs--2 text-align-center fw-bold activity_loading_placeholder">
            No tags found.
        </div>`
    }
    const tagHtml = tags
        .sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
        .map(tag => `
        <span class="badge rounded-pill bg-primary p-2 px-3">
            ${tag.name}
        </span>`).join("");
    return tagHtml;
}
const transposeLeadData = async(lead, params) => {
    const userObj = lead.event.user;
    const dataObj = lead.event.data;

    delete dataObj.name;
    delete dataObj.phone;
    delete dataObj.email;


    // do we show credit actions on this type of Lead?
    const creditableServiceTypes = [ 'car_finance_lead' ];
    let leadServiceType = lead.service_type;
    let isCreditableLeadType = creditableServiceTypes.includes(leadServiceType) || false;
    if (isCreditableLeadType) {
        document.getElementById('l_action-request_credit')?.classList.remove('hide');
    }

    // transpose primary details
    replaceInnerHtmlByEl(document.getElementById('l_name'), userObj.name);
    // add lead value
    const leadValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    }).format(lead.value || userObj.value || 0);
    replaceInnerHtmlByEl(document.getElementById('l_value'), leadValue);

    // add phone
    replaceInnerHtmlByEl(document.getElementById('l_phone'), `<a href="tel:${userObj.phone}">${userObj.phone}</a>`);
    // add email
    replaceInnerHtmlByEl(document.getElementById('l_email'), `<a href="mailto:${userObj.email}">${userObj.email}</a>`);
    // add tags
    replaceInnerHtmlByEl(
        document.getElementById('l_tags_container'),
        transposeLeadTags(lead.tags)
        );
        
    document.title = userObj.name + " | Lead profile - Convert Engine™";
    
    try {
        const parsedUnix = new Date(lead.timestamp);
        const initCreatedDate = parsedUnix.toLocaleDateString('en-US', {
            year: "numeric",
            weekday: "short",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        });
        replaceInnerHtmlByEl(
            document.getElementById('l_timestamp'),
            "Created " + initCreatedDate
        );
    } catch(err) {
        console.warn('CE:ERR - An error occured when attempting to update the Lead created at info:', err.message);
        document.getElementById('l_timestamp').classList.add('hide');
    }


    if (userObj.state) {
        replaceInnerHtmlByEl(
            document.getElementById('l_address_state'),
            userObj.state + " " + (userObj.country || "")
        );
    } else {
        const addressStateContainer = document.getElementsByClassName('l_address_state_container')?.[0]
        addressStateContainer?.parentNode?.removeChild(addressStateContainer)
    }

    if (!infoSectionStrings?.[lead.service_type]) {
        console.info('Is no custom style for lead, returning dotpoints')

        transposeRawDataSection(dataObj);

    } else {
        transposeCustomSection(dataObj, lead.service_type)
        
        transposeRawDataSection(dataObj);
    }

    const leadStatus = getLeadStatus(lead)
    
    const leadStatusPill = isCreditableLeadType ? `<span class="badge me-1 py-2 my-1 mx-2 badge-subtle-${leadStatus.state}" id="l_status_pill">
        ${leadStatus.title}
    </span>` : '';

    // eslint-disable-next-line max-len 
    replaceInnerHtmlByEl(
        document.getElementById('l_status'),
        `${leadStatusPill}
        <span class="badge me-1 py-2 my-1 mx-2 badge-subtle-secondary" id="l_assigned_pill">
            Assigned: Loading...
        </span>
        <span class="badge me-1 py-2 my-1 mx-2 badge-subtle-info" id="l_workflow_stage_pill">
            Stage: Loading...
        </span>`,
        true
    );


    if (["credited","pending", "rejected"].includes(leadStatus.status)) {
        console.info('Has invalid credit interactions')

        // insert history
        const creditHistoryHtml = lead.credit_interaction_history
        ?.map(item => transposeCreditHistoryEl(item)).join("") || "";

        replaceInnerHtmlByEl(
            document.getElementById('l_status_history_body'),
            creditHistoryHtml
        );

        // insert update explainer in footer
        const updateLeadCreditUpdateFooter = (update, reason, note) => {
            return `
                <h6 class="fs--1">${update}</h6>
                <span class="fs--1">(${reason}) ${note}</span>
            `
        }



        const updateContainer = document.getElementById('l_status_update_container');
        // credited-only actions
        if (leadStatus.status === "credited") {

            document.getElementById('l_action-request_credit')?.remove()
            replaceInnerHtmlByEl(
                updateContainer,
                updateLeadCreditUpdateFooter(
                    'Lead has been credited with the following note:',
                    lead.invalid?.reason,
                    lead.invalid?.note
                )
            );
        }

        // credit actions
        if (leadStatus.status === "rejected") {
            // lead credit was rejected, do something here
            document.getElementById('l_action-request_credit').innerText = "Re-submit credit";
            replaceInnerHtmlByEl(
                document.getElementById('l_status_pill'),
                "Credit rejected"
            );
            document.getElementById('l_status_pill').classList.add('btn-dark'); 

            replaceInnerHtmlByEl(
                updateContainer,
                updateLeadCreditUpdateFooter(
                    'Lead credit request was not approved:',
                    lead.invalid?.reason,
                    lead.invalid?.note
                )
            );
        } else if (leadStatus.status === "pending") {
            // credit request is still pending
            document.getElementById('l_action-request_credit')?.remove();
            replaceInnerHtmlByEl(
                updateContainer,
                updateLeadCreditUpdateFooter(
                    'Lead is pending a credit request with the following note:',
                    lead.invalid?.reason,
                    lead.invalid?.note
                )
            );
        }

        document.getElementById('l_status_container')?.classList.remove('hide')
    }

    // add the admin-level credit reasons
    if (params.m === "1") {

        const creditSelect = document.getElementById('credit-reason')
        // add internal/management options
        creditSelect.options[creditSelect.options?.length] = new Option("ADMIN ONLY: none_specified", "none_specified")
        creditSelect.options[creditSelect.options?.length] = new Option("ADMIN ONLY: admin_request", "admin_request")
    
    }

    if (lead.cc_id) {
        retrieveAndTransposeInteractionHistory(lead);
    } else {
        document.getElementById('l_cc_status_pill').innerText = "😴 Inactive";
        document.getElementById('l_cc_method_pill').remove();//.addClass('hide');
        // document.getElementById('l_cc_action-start_engine').classList.remove('hide');
        
        document.getElementById('l_cc_action_footer').remove();

        const html = `
            <div class="row cc-history-interstitial my-3 fs--2 text-align-center fw-bold">
                <span class="fw-bold fs-1">ConvertGPT is not active on this Lead</span>
                <div class="text-align-center mt-2">
                    <button class="btn btn-primary me-1 mb-1 w-50" 
                        id="l_cc_action-start_engine" 
                        data-bs-toggle="modal" 
                        data-bs-target="#ce_modal-start_engine">🤖⚡️ Enable ConvertGPT
                    </button>
                </div>
            </div>
        `
        replaceInnerHtmlByEl(
            document.getElementById('l_cc-instance-history_accordion_body-main'),
            html
        );
        

    }

    const checkAssignment = lead.lead_owner_id?.length ? 
            await window.convertEngine.retrievePublicUserInfoById(lead.lead_owner_id) : 
            null;
    const assignedUser = checkAssignment?.isOk ? checkAssignment.val : {};

    if (checkAssignment?.isErr) {
        console.warn('CE:ERR - An error occured when retrieving assigned user');
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!',
            'Could not retrieve the assigned user info. If this happens again, contact us.',
            'warning'
        )
    } else {
        // manage the assign self button el
        const assignSelfButtonEl = document.getElementById('l_action-assign_lead');
        if (assignSelfButtonEl) {
            assignSelfButtonEl.setAttribute('data-ce_lead_id', lead.id)
            assignSelfButtonEl.setAttribute('data-ce_project_id', lead.project_id)
            transposeOwnerOptionsForDropdown(
                document.getElementById('l_assign_lead_owner_options')
            )
        }

        // if (assignedUser?.id === window.convertEngine.user?.id && assignSelfButtonEl) {
        //     console.info('Assigned user is current user, remooving button')
        //     assignSelfButtonEl.remove();
        // }

        const assignedPillEl = document.getElementById('l_assigned_pill');

        if (assignedPillEl) {

            let assigned = assignedUser.id ? 
            "Assigned: " + (assignedUser.user?.name || "Unknown user") : 
            "Unassigned";
            
            replaceInnerHtmlByEl(
                assignedPillEl,
                assigned
            );
            assignedPillEl.classList.add(
                assignedUser.id ? 'badge-subtle-primary' : 'badge-subtle-danger'
            );
            assignedPillEl.classList.remove('badge-subtle-secondary');
            assignedPillEl.setAttribute('ce_lead_owner', assignedUser.id ? true : false)
            assignedPillEl.setAttribute('ce_lead_assigned', assignedUser.id)

        }

    }

    const leadStagePillEl = document.getElementById('l_workflow_stage_pill')
    if (lead.workflow_stage_id) {
        const workflow = await workflowStageInfoById(lead, lead.workflow_stage_id)
        if (workflow?.id) {
            replaceInnerHtmlByEl(
                leadStagePillEl,
                "Stage: " + workflow.title
            );
            leadStagePillEl.setAttribute('ce_lead_stage', workflow.id);
        }
    } else {
        replaceInnerHtmlByEl(
            leadStagePillEl,
            "Stage: None"
        );
        leadStagePillEl.classList.add('badge-subtle-danger');
    };

    const workflowConfig = await workflowConfigByProject(lead);
    const stageButtonEl = document.getElementById('l_assign_lead_workflow_stage');
    if (workflowConfig?.length) {
        const workflow = workflowConfig[0]
        if (!workflow.stages?.length) {
            stageButtonEl.disabled = true;
            replaceInnerHtmlByEl(
                stageButtonEl,
                "No stages found"
            );

        } else {
            let workflowStageOptsHtml = workflow.stages.map(stage => {
                const isCurrentStage = stage.id === lead.workflow_stage_id
                return `
                <a class="dropdown-item" href="#!" 
                data-ce_lead_id="${lead.id}" 
                data-ce_project_id="${lead.project_id}" 
                data-ce_workflow_id="${workflow.id}" 
                data-ce_workflow_stage="${stage.id}" 
                ${isCurrentStage ? "" : 'onclick="updateLeadWorkflowStage(this)"'}
                >
                    <span class="badge me-1 py-2 mx-2 badge-subtle-${stage.style || "primary"}">
                    ${stage.title}</span>
                </a>
            `}).join("");
            replaceInnerHtmlByEl(
                document.getElementById('l_assign_lead_workflow_stage_options'),
                workflowStageOptsHtml,
                true
            );
        }
    } else {
        stageButtonEl.disabled = true;
        replaceInnerHtmlByEl(
            stageButtonEl,
            "No workflows found"
        );
    }

    // update the activity history containers
    const activityInteractionFormEl = document.getElementById('l_activity_interaction_form');
    if (activityInteractionFormEl) {
        activityInteractionFormEl.setAttribute('ce_lead_id', lead.id)
        activityInteractionFormEl.setAttribute('ce_project_id', lead.project_id)
    }
    if (lead.activity_history?.length) {
        transposeLeadActivityHistory(lead);
    } else {
        document.getElementById('l_activity_container')
            .querySelector('.activity_loading_placeholder')
            .innerText = "No activity found."
    }

    return null;
}

const updateLeadCreditQualifier = (e) => {
    const val = e.value;
    const types = {
      bad_information: {
        title: "What is required in this request:",
        requirements: [
          "▪️ Information detailing which datapoints were incorrect & why.",
          "&nbsp;",
          "Please provide as much detail as possible to ensure quick & accurate assessment."
        ]
      },
      duplicate_lead: {
        title: "What is required in this request:",
        requirements: [
          "▪️ Convert Engine Lead ID of the Lead for which this Lead is a duplicate",
          // "▪️ If the Lead"
        ]
      },
      phone_disconnected: {
        title: "About this request:",
        requirements: [
          "Lead contact phone numbers received in qualification are SMS-verified. It may be possible that the customer has their phone set to automatically screen unknown callers, or is on do-not-disturb.",
          "&nbsp;",
          "<b>We recommend:</b>",
          "1️⃣ Multiple contact points across a 2-week period, including different times of day.",
          "2️⃣ Initiating a 5-day nurture sequence via ConvertGPT, which will also attempt to engage the customer alongside you.",
          "&nbsp;",
          "If you have attempted the above and still believe that this phone number is incorrect/disconnected, please provide detailed context for additional review."
        ]
      },
      fake_profile: {
        title: "What is required in this request:",
        requirements: [
          "▪️ Information detailing which datapoints were fake & why.",
          "&nbsp;",
          "Please provide as much detail as possible to ensure quick & accurate assessment."
        ]
      },
      testing_or_solicitation: {
        title: "What is required in this request:",
        requirements: [
          "▪️ Information detailing your interaction with the Lead & why you believe they are testing and not a genuine lead.",
          "&nbsp;",
          "Please provide as much detail as possible to ensure quick & accurate assessment."
        ]
      },
      wrong_category: {
        title: "What is required in this request:",
        requirements: [
          "▪️ Information detailing why you believe that this Lead is in the wrong category.",
          "&nbsp;",
          "Please provide as much detail as possible to ensure quick & accurate assessment."
        ]
      },
      already_serviced: {
        title: "About this request:",
        requirements: [
          "As per our Lead Credit Policy guidelines, this policy is only valid when a Lead indicates that the service was already started or completed with another vendor at a time before the lead was delivered.",
          "&nbsp;",
          "If you believe this criteria has been met, please provide as much detail as possible to ensure quick & accurate assessment."
        ]
      },
      do_not_call: {
        title: "What is required in this request:",
        requirements: [
          "▪️ Information detailing your interaction with the Lead & why they asked to not be called.",
          "&nbsp;",
          "Please provide as much detail as possible to ensure quick & accurate assessment."
        ]
      }
    }
  
    const copyValues = types[val];
  
    replaceInnerHtmlByEl(
        document.getElementById('credit-reason-qualifier-title'),
        copyValues.title || "Information required in this request:"
    );
  
    const qualifierInnerEl = document.getElementById('credit-reason-qualifier-inner')
    
    const requirements = copyValues.requirements.map(value => `<div>${value}</div>`).join("")

    replaceInnerHtmlByEl(
        qualifierInnerEl,
        requirements
    );

    return copyValues;
  };updateLeadCreditQualifier;//({ value: "bad_information" });

const requestCreditForLead = async(el) => {

    const lead = window.convertEngine.state.lead;

    if (!lead) return null;

    const leadStatus = getLeadStatus(lead);

    if (leadStatus.status === "pending") {
        console.info('Cannot re-attempt due to pending assessment')
        return null;
    };

    const reason = document.getElementById('credit-reason').value;
    const note = document.getElementById('credit-reason-detail').value;

    const imageFile = document.getElementById('credit-reason-assets').files?.[0];
    
    const image = await new Promise((res) => {
        if (!imageFile) {
            res(null);
            return null;
        }
        const fr = new FileReader();
        fr.addEventListener("load", evt => {
            return res(evt?.target?.result || null)
        });
        return fr.readAsDataURL(imageFile);
    })

    if (!reason || !note) {
        // alert("You must complete all fields to submit a request.");
        return null;
    }


    window.convertEngine.handleActionButtonState(el, 'credit', 'start')

    const creditRes = await window.convertEngine.updateStatusForLead(
        reason, 
        note, 
        image, 
        false, 
        true
    );

    if (creditRes.isErr) {
        console.error('CE:ERR - An error occured when attempting credit request:', creditRes);
        window.convertEngine.createToastAlert(
            '🤔 Uh oh!', 
            'An error occured when requesting credit, this may not have processed correctly. If this persists please contact us.', 
            'danger'
        )
        window.convertEngine.handleActionButtonState(el, 'credit', 'fail')
    } else {
        // say success and reload the page
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Credit request processed successfully'
        )
        window.convertEngine.handleActionButtonState(el, 'credit', 'success')
        setTimeout(() => {
            return window.location.reload();
        }, 2500)
    }
    return null;
};requestCreditForLead;

const renderNavigationOptionsForAccess = () => {
    const user = window.convertEngine.user;

    if (!user?.id) {
        console.info('CE: Not updating nav, there is no user');
        return null;
    };


    // check if they should see the 'lead management' options
    const associations = user.associations;
    const hasUploadAuth = associations.find(assoc => assoc.projects.find(proj => proj.id.includes("ce_backfill_lead")));
    if (hasUploadAuth) {
        const navleadBackfillEl = document.querySelector('[data-ce_nav="lead_backfill"]');
        // unhide the management options
        navleadBackfillEl?.classList.remove('hide');
    };

    // check if they have 'admin management' options
    // TODO: Add this in later for admin management inside of here 

    return null;
}

const addColorThemeListeners = () => {
    // define the logo element here
    const logoEl = document.getElementById('navbar-logo-img');
    // onload, replace the logo if it has the light version, but the theme is dark
    if (localStorage.getItem("theme") === "dark" && logoEl) {
        logoEl.src = logoEl.src.replace('convertengine.png', 'convertengine-alt.png');
    }
    return document.body.addEventListener(
        "clickControl",
        ({ detail: { control, value } }) => {

            // use this to modify theme controls based on colour theme
            if (control === "theme" && logoEl) {
                if (value === "dark" && logoEl.src.includes('convertengine.png')) {
                    logoEl.src = logoEl.src.replace('convertengine.png', 'convertengine-alt.png');
                }
                if (value === "light" && logoEl.src.includes('convertengine-alt.png')) {
                    logoEl.src = logoEl.src.replace('convertengine-alt.png', 'convertengine.png');
                }
            }
        }
    );
}
const tranposeSearchObjectLead = (searchLead, result) => {

    const leadInfo = searchLead.data || {};

    const storedLeadInfo = {
        project_id: leadInfo.project_id,
        id: leadInfo.id,
        data: leadInfo.data,
        company: leadInfo.company,
    }

    // TODO: Add more data to live results from search
    if (result) {
        console.log(result)
    };

    return `
    <a class="dropdown-item px-x1 py-2" 
        href="/app/leads/profile.html?i=${leadInfo.id}&p=${leadInfo.project_id}"
        onclick="handleSearchLink(event, this)"
        data-ce_search_type="lead"
        data-ce_search_id="${leadInfo.id}"
        data-ce_search_data='${JSON.stringify(storedLeadInfo || {})}'
        data-ce_search_target
    >
        <div class="d-flex align-items-center overflow-hidden">
            <div class="file-thumbnail me-2">
                <span class="fs-1 mt-1 me-3 fas fa-user"></span>
            </div>
            <div class="flex-1">
                <h6 class="mb-0 title">${leadInfo?.data?.name}</h6>
                <p class="fs--2 mb-0 d-flex">
                    <span class="fw-medium text-600 ms-2">${leadInfo?.data?.phone || ""} • ${leadInfo?.data?.email || ""}</span>
                </p>
            </div>
        </div>
    </a>`
}
const tranposeSearchObject = (historyObj, result = false) => {
    const type = historyObj.type;

    switch(type) {
        case 'lead': {
            return tranposeSearchObjectLead(historyObj, result)
        };
        default: {
            console.warn('CE: Unsupported history viewed object heard:', type);
            return ''; // return an empty string;
        }
    };
}

const handleSearchLink = async(event, el) => {
    const maximumRecentViewedLength = 8

    event.preventDefault();

    const targetEl = el;

    const history = window.convertEngine.search_history;

    if (!targetEl) {
        console.warn('CE:ERR - HandleSearchLink found no element, this shouldnt happen');
        return null;
    }

    // retrieve ID & Href values
    const href = targetEl.href;//.getAttribute('data-href');
    const id = targetEl.getAttribute('data-ce_search_id');

    // if ID does not exist in recent viewed, add it to the top of the array

    if (!history.recent_viewed?.find(x => x.data?.id === id)) {
        const type = targetEl.getAttribute('data-ce_search_type');
        let data = targetEl.getAttribute('data-ce_search_data');
        try {
            data = JSON.parse(data);
        } catch(err) {
            console.warn('CE:Warn - could not parse search el data:', err.message)
        }
        history.recent_viewed.push({ type, data });
        // if >5 links, remove from array
        if (history.recent_viewed?.length > maximumRecentViewedLength) history.recent_viewed.shift();

        window.convertEngine.setSearchHistory();
    }

    if (event.ctrlKey) {
        // TODO: handle ctrl + click here
    }
    window.location = href;
    return null;
};handleSearchLink;

const renderRecentSearchOptions = () => {

    // const exampleSearches = {
    //     recent_searches: [
    //         {
    //             type: "all",
    //             value: "Tester",
    //             id: 12121212
    //         },{
    //             type: "all",
    //             value: "04123",
    //             id: 21212121
    //         }
    //     ],
    //     recent_viewed: [
    //         {
    //             type: "lead",
    //             data: {
    //                 id: "123456",
    //                 project_id: "2211",
    //                 name: "Jimmy jones",
    //                 phone: "0411234567",
    //                 email: "jimjones@outlook.com" 
    //             }
    //         },
    //         {
    //             type: "lead",
    //             data: {
    //                 id: "654321",
    //                 project_id: "1122",
    //                 name: "Jay Farrell",
    //                 phone: "0403145518",
    //                 email: "jay.farrell@live.com.au"
    //             }
    //         }
    //     ]
    // }
    // console.log(exampleSearches)

    const categoryStrings = {
        all: "All Categories",
        project: "Projects",
        lead: "Leads",
        segment: "Segments"
    }

    // load in the base, is there any localstate with searches?
    // const history = exampleSearches//window.convertEngine.search_history;
    const history = window.convertEngine.search_history || {};
    
    const searchResContainer = document.getElementById('ce_search_result_container');

    // transpose the recent history
    if (!history.recent_searches?.length) {
        document.getElementById('ce_search_recent_search_container')?.remove()
    } else {
        const recentSearchHtml = history.recent_searches.map(search => `
            <a class="dropdown-item px-x1 py-2" 
                href="#!"
                onclick="handleSearchPopulate(this)"
                data-ce_search_value="${search.value}"
            >
                <div class="d-flex align-items-center">
                <div class="file-thumbnail me-2">
                    <span class="fs-1 mt-1 me-3 fas fa-search"></span>
                </div>
                <div class="flex-1">
                    <h6 class="mb-0 title">"${search.value}"</h6>
                    <p class="fs--2 mb-0 d-flex">
                        <span class="fw-medium text-600 ms-2">${categoryStrings[search.type]}</span>
                    </p>
                </div>
                </div>
            </a>
        `).join("");

        const fullRecentSearchHtml = `
            <div id="ce_search_recent_search_container" data-ce_search_helper="search">
                <h6 class="dropdown-header fw-medium text-uppercase px-x1 fs--2 pt-0 pb-2">Recent searches</h6>
                ${recentSearchHtml}
                ${history.recent_viewed?.length ? '<hr class="text-200 dark__text-900">' : ''}
            </div>
        `

        replaceInnerHtmlByEl(
            searchResContainer,
            fullRecentSearchHtml,
            false
        )
    };

    if (!history.recent_viewed?.length) {
        document.getElementById('ce_search_recent_viewed_container')?.remove()
    } else {
        const recentViewedHtml = history.recent_viewed.reverse()
            .map(viewed => tranposeSearchObject(viewed)).join("");

        const fullRecentViewedHtml = `
            <div id="ce_search_recent_viewed_container" data-ce_search_helper="viewed">
                <h6 class="dropdown-header fw-medium text-uppercase px-x1 fs--2 pt-0 pb-2">Recently viewed</h6>
                ${recentViewedHtml}
            </div>
        `

        replaceInnerHtmlByEl(
            searchResContainer,
            fullRecentViewedHtml,
            history.recent_searches?.length ? true : false
        );
    };

    // if no searches on either, then replace with 'no history, type something.. etc'
    if (!history.recent_searches?.length && !history.recent_viewed?.length) {
        replaceInnerHtmlByEl(
            document.getElementById('ce_search_result_container'),
            `<div class="fs--2 text-align-center fw-bold text-secondary">
                No search history, start typing!
            </div>`
        ); 
    };
}

const handleSearchQuery = async(prefill) => {
    const maximumRecentSearchedLength = 2; // max amount of recent searches to display
    const maximumSearchResults = 20; // max amount of search results to show in one query
    const minSearchLength = 3; // minimum amount of characters for search

    const searchResultContainer = document.getElementById('ce_search_result_container');

    const searchBox = document.getElementById('ce_search');

    const searchType = searchBox.getAttribute('data-ce_search_query_type') || 'all';

    // if there is a prefill value from a previous search, add it before querying
    if (prefill) {
        searchBox.value = prefill;
        // reset the search id
    };

    let val = searchBox.value;

    const handleSearchPersistence = async() => {
        const searchId = window.convertEngine.state.search_history_id;
        if (val.length > 0 && !prefill) {
            // this is an existing search, and we should update based on the state
            const indexOfSearch = window.convertEngine.search_history?.recent_searches?.findIndex(
                x => (x.id === searchId || x.value?.toLowerCase() === val.toLowerCase())
            );
            if (indexOfSearch < 0) {
                const newSearchId = Date.now();
                window.convertEngine.state.search_history_id = newSearchId;
                console.info('is actually a new search, should save it')
                window.convertEngine.search_history?.recent_searches.push({
                    type: searchType,
                    id: newSearchId,
                    value: val
                })
            }
        } else {
            // this is where we should log & update search queries
            const newSearchId = Date.now();
            window.convertEngine.state.search_history_id = newSearchId;
            window.convertEngine.search_history?.recent_searches.push({
                type: searchType,
                id: newSearchId,
                value: val
            })
        };
        if (
            window.convertEngine.search_history?.recent_searches?.length 
                > 
            maximumRecentSearchedLength
        ) {
            window.convertEngine.search_history?.recent_searches.shift();
        }

        return window.convertEngine.setSearchHistory();
    }



    if (val.length <= 0) {
        // reset the search ID
        window.convertEngine.state.search_history_id = 0;
        return renderRecentSearchOptions();
    } else if (val?.length < minSearchLength) {
        return replaceInnerHtmlByEl(
            searchResultContainer,
            `<div class="fs--2 text-align-center fw-bold text-secondary">
                Type ${3 - val?.length} more characters to search
            </div>`
        );
    } else {
        replaceInnerHtmlByEl(
            searchResultContainer,
            `<div class="text-align-center">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>`
        )
    };

    const projects = window.convertEngine.retrieveProjectsFromAssociations();

    const mapValidSearchQueryResults = (res) => {
        if (res.isOk) return res.val?.res || null;
        else return null;
    }

    const searchRes = await Promise.all(
        projects.map(
            async(project) => window.convertEngine.searchQueryForProject(project, val)
        )
    )
    
    const searchResResults = searchRes
        .map(x => mapValidSearchQueryResults(x))
        .filter(x => x !== null)
        .flat();

    handleSearchPersistence();

    
    if (!searchResResults?.length) {
        return replaceInnerHtmlByEl(
            searchResultContainer,
            `<div class="fs--2 text-align-center fw-bold text-secondary">
            No results found.
            </div>`
            );
    } else {
        const sortedSearchResResults = searchResResults.sort(
            (a,b) => a.timestamp > b.timestamp ? 1 : -1
        ).slice(0, maximumSearchResults);

        const searchResHtml = sortedSearchResResults.map(x => tranposeSearchObject(x, true));
        
        replaceInnerHtmlByEl(
            searchResultContainer,
            searchResHtml
        )

    }

    return null;
};

const handleSearchPopulate = (el) => {

    const targetEl = el;

    const value = targetEl.getAttribute('data-ce_search_value');

    return handleSearchQuery(value);

};handleSearchPopulate;

const handleSearchFunctions = () => {

    const searchDebounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback.apply(null, ...args);
            }, wait);
        };
    }

    const searchBox = document.getElementById('ce_search');

    // if (!search)

    searchBox.addEventListener('keyup', 
        searchDebounce(
            handleSearchQuery, 
            500
        )
    )

    renderRecentSearchOptions();

    // listener for dismiss button, if search is dismissed, dismiss the results too
    const searchDismissBtn = document.querySelector('[data-bs-dismiss="search"][data-ce_main_search]');
    searchDismissBtn && searchDismissBtn.addEventListener('click', () => {
        if (window.convertEngine?.state?.search_history_id) {
            window.convertEngine.state.search_history_id = 0;
        };
        renderRecentSearchOptions();
    })
    

}

window.addEventListener('load', async function(event) {

    addColorThemeListeners();

    const pagepath = window.location.pathname;

    if (pagepath === "/login.html") handleLoginFunctions();

    if (pagepath === "/app/leads/list.html") handleLeadCrmFunctions();

    if (pagepath === "/app/leads/workflow.html") handleLeadWorkflowFunctions();

    const createOrImportLeadPagePaths = [
        "/app/leads/create.html",
        "/app/ext/import_lead.html"
    ]
    if (createOrImportLeadPagePaths.includes(pagepath)) handleCreateNewLeadFunctions();

    console.info('ce: pageload:', event);

    const params = window.convertEngine.getQueryStringParams();

    if (document.getElementById('ce_search')) handleSearchFunctions();

    renderNavigationOptionsForAccess();

    const profilePagePaths = [
        "/app/leads/profile.html",
        "/app/ext/profile.html"
    ]
    if (profilePagePaths.includes(pagepath)) {
        console.info('is trying to load a Lead here')
        const returnedLead = await window.convertEngine.getLeadById(params.i, params.p);
        if (returnedLead.isOk) return transposeLeadData(returnedLead.val, params)
        else {
            console.warn('CE:WARN - an error occured when attempting to retrieve the specified lead:', returnedLead)
            window.convertEngine.createToastAlert(
                "⚠️ Permissions Error", 
                returnedLead.val, 
                "warning"
            );
        }
    }


    return null;
});

