const replaceInnerHtmlByEl = (
    el, // target element
    value, // value to add to the html
    append = false // if true will attempt to append this to the existing innerHTML
) => {
    try {
        const targetEl = el;
        // if it doesn't exist, log it
        if (!targetEl || targetEl === null) {
            console.warn('CE: Unable to find EL to replace HTML, cannot update this:', { el, value });
            return null;
        };
        // else try to update it
        if (append) targetEl.innerHTML+= value;
        else targetEl.innerHTML = value;
    } catch(err) {
        // errors? Handle it here
        console.warn('CE: Unable to update innerHTML of element:', { el, value });
    };
    return null;
};

const workerExportFunctions = {
    replaceInnerHtmlByEl
}
export default workerExportFunctions;